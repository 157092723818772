import React, { useEffect, useRef, useState } from "react";
import Timer from 'react-compound-timer';
import Swal from 'sweetalert2';
import './css/style.css';
import { Link, Redirect } from "react-router-dom";
import { Progress, Modal, ModalHeader, ModalBody, CustomInput } from 'reactstrap';
import { connect } from 'react-redux';
import { inputChange, flagQuestion } from './../../../redux/actions/authActions';
import PropTypes from "prop-types";
import Header from './../../includes/header/header.component';
import Sidebar from './../../includes/sidebar.component';
import QuestionBox from '../../includes/questionBox.component';

const Exam = props => {  
    const mounted = useRef(); 
    const [modal, setModal] = useState(false);
  
    const toggle = () => {     
        setModal(!modal); 
    }  
    useEffect(()=>{
        if (!mounted.current) {
            // do componentDidMount logic
            mounted.current = true;
            window.scrollTo(0, 0);  
            props.inputChange('redirect', false); 
            props.inputChange('location', '/review');
            props.inputChange('privacyPage', false);  
        } else {
            // do componentDidUpdate logic  
                             
          } 	       
    })      
    const {
        selectedSubject,
        selectedYear,
        selectedSubjectImg, 
        selectedCategoryImg,
        selectedCategory,
        questions,
        currentQuestion,
        progressBarStatus,
        progressBarUnit,
        questionTags,
        questionTime,
        redirect,
        location,
        isAuthenticated,
        showResult,
        speedRange1,
        speedRange2,
        speedRange3,
        report1,
        report2,
        report3,
        report4,
        report5,
        report6,
        report7, 
        subjectTag   
    }=props;     
    
    const questionList = () => {         
        if(questions.length){                        
            return <QuestionBox            
                        QuestionNo={currentQuestion+1}
                        QuestionTitle={questions[currentQuestion].question}
                        options={questions[currentQuestion].options}  
                        imagePosition ={questions[currentQuestion].question_position}   
                        image= {questions[currentQuestion].question_image} 
                        handleReport ={handleFlagQuestion}               
                    />              
            
        }else{
           return <h5>0ops!, No Question found</h5>
        }
		
    }
    const handleRenavigation = async questionNumber => {
        props.inputChange('currentQuestion', questionNumber); 
        let myQuestionNumber = questionNumber +1;       
        props.inputChange('progressBarStatus', myQuestionNumber * progressBarUnit);         
    };
    const questionTagsList = () => {         
        if(questionTags.length){
            return questionTags.map((item, index) => { 
                return  <div className="col-md-3">
                            <Link onClick={handleRenavigation.bind(this,index)}><span className={`status ${item ===1? 'pending' : item ===2? 'attempted': 'skipped'}`}>{index+1}</span></Link>
                        </div>                          
            })
        }else{
           return <h5>0ops!, No tag found</h5>
        }        
    }
    const handleCloseBox = e =>{   
        e.preventDefault();
        if(showResult){
            props.inputChange('showResult', false);	 
        }         
    }
    const handleFlagQuestion = () => {
        setModal(true)
    };
    const reportQuestion = () => {
        let questionId =  questions[currentQuestion].question_id;
        let message = `The question with id ${questionId} has the following complaints:`;
        if(report1){
          message+=' Typographical error, '
        }
        if(report2){
          message+=' Incomplete question and answer, '
        }
        if(report3){
          message+=' Images does not look quite well, '
        }
        if(report4){
          message+=' No Image, '
        }
        if(report5){
          message+=' Duplicate Options, '
        }
        if(report6){
          message+=' Wrong Answer, '
        }
        if(report7){
          message+=report7
        }
        const data = {
          message
        }
        console.log(data)
        props.flagQuestion(data)
        setModal(false)
    }; 
    const handleChange = (e) => {  
        const target = e.target;
        const name = target.id;
        const value = target.value;          
		props.inputChange(name, name === 'report1'? !report1:name === 'report2'?!report2:name === 'report3'?!report3:name === 'report4'?!report4:name === 'report5'?!report5:name === 'report6'?!report6:name === 'report7'?value:'');	
    } 

	return (        
		<>   
           {redirect ? <Redirect to={location} /> : null}          
           <Header/>           
           <div className="container-fluid Exam" onClick={handleCloseBox}>
                <div className="row">
                   <Sidebar/>                   
                   <div className="col-md-7 afterNav">                      
                        <div className="row">                          
                            <div className="col-md-2 push33">
                               <span className="headingOne">Examination:</span>   
                            </div>
                            <div className="col-md-4 badgeSection">
                                <span className="categoryBadge first">                                   
                                    <> <img src={selectedCategoryImg} alt='logo' className="myBadge"/> {selectedCategory} </>        
                                </span>                              
                            </div>                           
                        </div>
                        <div className="row p3">                           
                            <div className="col-md-1 push33">
                                <span className="headingOne">{subjectTag}:</span>   
                            </div>
                            <div className="col-md-6 badgeSection">
                                <span className="categoryBadge second"><img src={selectedSubjectImg} alt='logo' className="myBadge"/> {selectedSubject}</span>                              
                            </div>
                            <div className="col-md-1 push33">
                                <span className="headingOne">Year:</span>   
                            </div>
                            <div className="col-md-2 badgeSection">
                                <span className="categoryBadge third">{selectedYear}</span>                              
                            </div>
                        </div>
                        <div className="row p4 mobileOnly">
                            <div className="col-md-12">
                            <span className="headingOne timerTitle">Time Left:</span> <span className="timer">
                                <Timer
                                    initialTime={questionTime}
                                    lastUnit="m"
                                    direction="backward"
                                    checkpoints={[
                                        {
                                            time: 600000,
                                            callback: () => Swal.fire('Time Left!', 'You have 10 mins left')
                                        }, 
                                        {
                                            time: speedRange3,
                                            callback: () =>  props.inputChange('speed', 'Good')
                                        },
                                        {
                                            time: speedRange2,
                                            callback: () =>  props.inputChange('speed', 'Medium')
                                        },  
                                        {
                                            time: speedRange1,
                                            callback: () =>  props.inputChange('speed', 'fair')
                                        },                                                                            
                                        {
                                            time: 0,
                                            callback: () => {
                                                Swal.fire('Time Up!', 'Thanks for attempting the test').then((result) => {
                                                    if(isAuthenticated){
                                                        Swal.fire(
                                                            'Submitted!',
                                                            'Your test details are been recorded.',
                                                            'success'
                                                        )       
                                                    }else{
                                                        Swal.fire({
                                                            title: 'Thanks!',
                                                            text: 'Please create free account/login to see your test result.',
                                                            icon: 'warning',
                                                            showCancelButton: true,
                                                            confirmButtonText: 'Login',
                                                            cancelButtonText: 'Create Account'
                                                        }).then((result) => {
                                                            if (result.value) {
                                                                props.inputChange('showLogin', true);
                                                            } else if (result.dismiss === Swal.DismissReason.cancel) {
                                                                props.inputChange('showSignup', true);
                                                            }
                                    
                                                        }) 
                                                        props.inputChange('location', '/');
                                                        props.inputChange('redirectToReview', true);                    
                                                    }
                                                    props.inputChange('redirect', true); 
                                                    props.inputChange('currentQuestion', 0);
                                                    props.inputChange('speed', 'Slow');

                                                }) 
                                            } 
                                        }                                         
                                    ]}
                                >
                                    {() => (
                                        <React.Fragment>
                                            <Timer.Minutes /> mins <Timer.Seconds /> sec                                        
                                        </React.Fragment>
                                    )}
                                </Timer>
                             </span>                           
                            </div>
                        </div>
                        <div className="row myProgress push4">
                            <div className="col-md-12">
                                <Progress animated  color="success" value={progressBarStatus} />
                            </div>
                        </div>
                        {questionList()}                        
                   </div>
                   <div className="col-md-3 timerSection desktopOnly">
                      <div className="row push3">
                            <div className="col-md-12">
                                <span className="headingOne timerTitle">Time Left:</span> <span className="timer">
                                <Timer
                                    initialTime={questionTime}
                                    lastUnit="m"
                                    direction="backward"
                                    checkpoints={[
                                        {
                                            time: 600000,
                                            callback: () => Swal.fire('Time Left!', 'You have 10 mins left')
                                        }, 
                                        {
                                            time: speedRange3,
                                            callback: () =>  props.inputChange('speed', 'Good')
                                        },
                                        {
                                            time: speedRange2,
                                            callback: () =>  props.inputChange('speed', 'Medium')
                                        },  
                                        {
                                            time: speedRange1,
                                            callback: () =>  props.inputChange('speed', 'fair')
                                        },                                                                            
                                        {
                                            time: 0,
                                            callback: () => {
                                                Swal.fire('Time Up!', 'Thanks for attempting the test').then((result) => {
                                                    if(isAuthenticated){
                                                        Swal.fire(
                                                            'Submitted!',
                                                            'Your test details are been recorded.',
                                                            'success'
                                                        )       
                                                    }else{
                                                        Swal.fire({
                                                            title: 'Thanks!',
                                                            text: 'Please create free account/login to see your test result.',
                                                            icon: 'warning',
                                                            showCancelButton: true,
                                                            confirmButtonText: 'Login',
                                                            cancelButtonText: 'Create Account'
                                                        }).then((result) => {
                                                            if (result.value) {
                                                                props.inputChange('showLogin', true);
                                                            } else if (result.dismiss === Swal.DismissReason.cancel) {
                                                                props.inputChange('showSignup', true);
                                                            }
                                    
                                                        }) 
                                                        props.inputChange('location', '/');
                                                        props.inputChange('redirectToReview', true);                    
                                                    }
                                                    props.inputChange('redirect', true); 
                                                    props.inputChange('currentQuestion', 0);
                                                    props.inputChange('speed', 'Slow');

                                                }) 
                                            } 
                                        }                                         
                                    ]}
                                >
                                    {() => (
                                        <React.Fragment>
                                            <Timer.Minutes /> mins <Timer.Seconds /> sec                                        
                                        </React.Fragment>
                                    )}
                                </Timer>
                                    
                                </span> 
                            </div>
                      </div>
                      <div className="row checkUps center">
                           {questionTagsList()}                                                               
                      </div>
                      <div className="row push3 push2">
                          <div className="col-md-12">
                                <div className="row">
                                    <div className="col-md-3">
                                        <span className="status attempted">&nbsp;</span>
                                    </div>  
                                    <div className="col-md-3">
                                        Attempted
                                    </div> 
                                </div>
                                <div className="row">
                                    <div className="col-md-3">
                                        <span className="status skipped">&nbsp;</span>
                                    </div>  
                                    <div className="col-md-3">
                                        Skipped
                                    </div> 
                                </div>
                                <div className="row">
                                    <div className="col-md-3">
                                        <span className="status pending">&nbsp;</span>
                                    </div>  
                                    <div className="col-md-3">
                                        Pending
                                    </div> 
                                </div>
                          </div>
                           
                      </div>
                   </div>
                </div>
           </div>
           <Modal isOpen={modal} toggle={toggle} className="reportModalClass">               
                <ModalHeader toggle={toggle}>Report An Issue</ModalHeader>
                <ModalBody>                    
                    <div className="container-fluid forgotPassword">
                        <div className="row">
                            <div className="col-12">                                                                          
                                <div className="row">                          
                                    <div className="col-12 push333">
                                        <CustomInput type="checkbox" id="report1" label="Typographical error" checked={report1} onChange={handleChange}/>
                                    </div>   
                                    <div className="col-12 push333">
                                        <CustomInput type="checkbox" id="report2" label="Incomplete question/answer" checked={report2} onChange={handleChange}/>
                                    </div>   
                                    <div className="col-12 push333">
                                        <CustomInput type="checkbox" id="report3" label="Image does not look quite right" checked={report3} onChange={handleChange}/>
                                    </div> 
                                    <div className="col-12 push333">
                                        <CustomInput type="checkbox" id="report4" label="No image" checked={report4} onChange={handleChange}/>
                                    </div>    
                                    <div className="col-12 push333">
                                        <CustomInput type="checkbox" id="report5" label="Duplicate option(s)" checked={report5} onChange={handleChange}/>
                                    </div>
                                    <div className="col-12 push333">
                                        <CustomInput type="checkbox" id="report6" label="Wrong answer" checked={report6} onChange={handleChange}/>
                                    </div>  
                                    <div className="col-12 push333">
                                        <input placeholder="eg. Something else..." id="report7" value={report7}  onChange={handleChange}/>
                                    </div>                                           
                                </div>    
                                <div className="row relative">                          
                                    <div className="col-12">
                                        <input type="submit" value="Submit" onClick={reportQuestion}/>                               
                                    </div>                                                  
                                </div>                     
                            </div>                                
                        </div>
                    </div>     
                </ModalBody> 
            </Modal> 
        </>
	);
};

Exam.propTypes= {   
    inputChange: PropTypes.func.isRequired,  
}

const mapStateToProps = state => ({  
    selectedSubject: state.auth.selectedSubject,
    selectedYear: state.auth.selectedYear,  
    selectedSubjectImg: state.auth.selectedSubjectImg,
    category: state.auth.category,
    questions: state.auth.questions,
    currentQuestion: state.auth.currentQuestion,
    progressBarStatus: state.auth.progressBarStatus,
    progressBarUnit: state.auth.progressBarUnit,
    questionTags: state.auth.questionTags,
    questionTime: state.auth.questionTime,   
    redirect: state.auth.redirect,
    location: state.auth.location,
    isAuthenticated: state.auth.isAuthenticated,
    showResult: state.auth.showResult,
    speedRange1: state.auth.speedRange1,
    speedRange2: state.auth.speedRange2,
    speedRange3: state.auth.speedRange3, 
    selectedCategoryImg: state.auth.selectedCategoryImg,
    selectedCategory: state.auth.selectedCategory,
    report1: state.auth.report1,
    report2: state.auth.report2,
    report3: state.auth.report3,
    report4: state.auth.report4,
    report5: state.auth.report5,
    report6: state.auth.report6,
    report7: state.auth.report7,
    subjectTag: state.auth.subjectTag 
})
export default connect(mapStateToProps, {inputChange, flagQuestion})(Exam);