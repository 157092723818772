import React from "react";

const History = props => {
	return (
        <div className="row">
            <div className="col-md-4">
                <h5>{props.category} - {props.subject}</h5><br/>
                <span className="grey">{props.questionNumber} Questions | </span> <span className="green">{props.timeLength}mins</span><br/><small>{props.date}</small>
            </div>
            <div className="col-md-8">
                <div className="row">
                    <div className="col-md-2">
                        CORRECT<br/><span className="correct">{props.correct}</span>
                    </div>
                    <div className="col-md-2">
                        WRONG<br/><span className="correct wrong">{props.wrong}</span>    
                    </div>
                    <div className="col-md-2">
                        SKIPPED<br/><span className="correct skip">{props.skipped}</span>  
                    </div>
                    <div className="col-md-2">
                        %<br/><span>{props.percentage}</span>  
                    </div>
                    <div className="col-md-2">
                        REMARK<br/><span>{props.remark}</span>  
                    </div>
                    <div className="col-md-2">
                        VELOCITY<br/><span>{props.velocity}</span>  
                    </div>
                </div>
            </div>
        </div>
                              
	);
};
export default History;