import React, { useEffect, useRef, useState }  from "react";
import { Modal, ModalHeader, ModalBody, Alert } from 'reactstrap';
import { connect } from 'react-redux';
import PropTypes from "prop-types";
import { Link, Redirect} from "react-router-dom";
import { inputChange, registerUser, loginUser, resetPassword, search, loadQuestions } from './../../../redux/actions/authActions';
import { clearErrors } from './../../../redux/actions/errorActions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import { GoogleLogin } from 'react-google-login';
import DropdownBox from './../dropDownBox.component';
import "./css/style.css";
import "./css/signUp.css";
import "./css/login.css";
import "./css/forgotPassword.css";


const Header = props => {
    const mounted = useRef(); 
   
    const [modal, setModal] = useState(false);
    const toggle = () => {
        props.inputChange('showMessage', false);
        props.inputChange('showSignup', false);
        props.clearErrors();
        setModal(!modal); 
    }
    const [modal2, setModal2] = useState(false);
    const toggle2 = () => {
        props.inputChange('showMessage', false);
        props.inputChange('showLogin', false);
        props.clearErrors();
        setModal2(!modal2); 
    }   

    const [modal3, setModal3] = useState(false);
    const toggle3 = () => {
        setModal2(false); 
        setModal3(!modal3); 
    }
    const [modal4, setModal4] = useState(false);
    const toggle4 = () => {
        props.inputChange('showMessage', false);        
        props.clearErrors();
        setModal4(false); 
    }
    const {       
        footerMoreOptions,
        WAEC,
        JAMB,       
        email,
        password,  
        confirmPassword,  
        firstname,
        lastname,
        mobile,
        sticky,
        showMessage,
        showMessageClass,
        error,
        isAuthenticated,
        firstName,
        lastName,
        logout,
        keyword,
        isLoading,
        searchResults,
        showResult,
        privacyStatus,
        privacyPage,
        showLogin,
        showSignup ,
        categories     
    } = props; 

    useEffect(()=>{
        if (!mounted.current) {
            // do componentDidMount logic
            mounted.current = true;
            setInterval(function(){ window.addEventListener("scroll", handleScroll); }, 6000);              
            props.inputChange('showMessage', false);  
            props.inputChange('logout', false);	              
        } else {  
            if(showLogin && !modal2){
                toggle2()
            }
            if(showSignup && !modal){
                toggle()
            }         
            if(error.id ==='REGISTER_SUCCESS' || error.id ==='RESET_PASSWORD_SUCCESS'){                
                props.inputChange('showMessageClass', 'success');   
                setModal(false);
                setModal4(true)            
            }else{
                props.inputChange('showMessageClass', 'danger'); 
            }
            if(error.id ==='LOGIN_SUCCESS'){                
                 toggle2();            
            }           
            if(error.id ==='REGISTER_FAILURE' || error.id ==='REGISTER_SUCCESS' || error.id ==='LOGIN_FAILURE' || error.id ==='RESET_PASSWORD_SUCCESS'){
                props.inputChange('showMessage', true);
            }  
          } 	       
    }) 
  
    const handleChange = (e) => {		
        const target = e.target;
		const name = target.name;
		const value = target.type === 'checkbox' ? target.checked : target.value;
		props.inputChange(name, value);	
    } 
    
    const handleSearch =(e) => {
        const target = e.target;
		const name = target.name;
		const value = target.value;
		props.inputChange(name, value);	
		if(keyword.length > 2){
            props.inputChange('isLoading', true);	
			const data ={
				keyword					
			}
			props.search(data);			 
		}else{
            props.inputChange('isLoading', false);	
            const clearSearch = [];
            props.inputChange('searchResults', clearSearch);
            props.inputChange('showResult', false);
		}
	}
    
    const handleRegistration = (e) => {		
        e.preventDefault();
        			
        const data ={
            email,
            password,
            firstname,
            lastname,
            mobile,
            confirmPassword          
        }	       
        props.registerUser(data)
    }
    
    const handleLogin = (e) => {		
        e.preventDefault();
        			
        const data ={
            email,
            password           
        }	
        console.log(data)
        props.loginUser(data);
    }
    
    const handleLogout = (e) => {		
        e.preventDefault();
        localStorage.removeItem('token');
        props.inputChange('logout', true);	 
        props.inputChange('isAuthenticated', false);
        handleMoreOptions('close');   	 
	}     
     
    const handleScroll = () => {
      if(window.scrollY>79){
        props.inputChange('sticky', true);        
      }else{
        props.inputChange('sticky', false); 
      }
    }

    const handleForgotPassword = (e) => {		
        e.preventDefault();
        			
        const data ={
            email                 
        }	
        props.resetPassword(data);
	} 
    
    const handleMoreOptions = (status, e) =>{
        if(status === 'open'){
            props.inputChange('footerMoreOptions', !footerMoreOptions); 
        }else{
            props.inputChange('footerMoreOptions', false); 
        }      
    } 

    const handleTakeExam = (subjectId, subject, subjectImage, year, e) =>{
        e.preventDefault();        
        props.inputChange('selectedSubject',subject);
        props.inputChange('selectedYear',year);
        props.inputChange('selectedSubjectImg',subjectImage); 
        props.loadQuestions(subjectId);           
    } 

    const handleCategoryNaviagtion = (category, e) =>{               
        props.inputChange('category', category);   
        handleMoreOptions('close');               
    }

    const handlePrivacySection = (category, e) =>{               
        props.inputChange('privacyStatus', category);
    }

    const categoryList = () => {         
        if(categories && categories.length){
            return categories.map((item, index) => { 
                return  <DropdownBox  index={index} key={item.exam_id} title={item.exam_name} image={item.exam_image} categoryId={item.category_id} examId={item.exam_id}/>
           })
        }
    }

    const JAMBList = () => {         
        if(JAMB.length){
            return JAMB.map((item, index) => {  
                if(index<8){                            
                    return <li key={index}> <Link to='/category' onClick={ handleCategoryNaviagtion.bind(null,2) }>{item.subject}</Link></li>         
                }  
           })
        }
    }

    const onFailure = (error) => {
        console.log(error);
    };    
    
    const facebookLoginResponse = (response) => {
        let token = response.accessToken;
        console.log(token)
        const data ={
            token                     
        }	 
     
        props.loginUser(data, false, true);
    };
    
    const facebookSignupResponse = (response) => {
        let token = response.accessToken;
        const data ={
            token                     
        }      
      
        props.registerUser(data, false, true);
    };

    const googleLoginResponse = (response) => {
        let token = response.accessToken;   
        console.log(token)    
        const data ={
            token                     
        }       
        props.loginUser(data,true);
    };

    const googleSignupResponse = (response) => {
        let token = response.accessToken;
        const data ={
            token                     
        }      
        props.registerUser(data,true);
    };
    
    const handleCloseBox = e =>{   
        e.preventDefault();
        if(showResult){
            //props.inputChange('showResult', false);	 
        }         
    }
    
    const mySearchResults = () => {         
        if(searchResults.length){
            return searchResults.map((item) => { 
                return <li><Link to='/' alt={item.subject_id} onClick={handleTakeExam.bind(this, item.subject_id, item.subject, item.subject_image, item.exam_year)}>{item.exam_name} {item.subject} {item.exam_year}</Link> </li>                       
            })
        }else{
           return <h5>0ops!, No result found</h5>
        }        
    } 
    const handleAuthFormDisplay  = (category, e) => {  
        e.preventDefault();             
        if(category === 'login'){
            setModal(false); 
            setModal2(true); 
        }else{           
            setModal2(false); 
            setModal(true); 
        }
    }
    
	return (
        <>		
        {logout ? <Redirect to='/' /> : null}    
            <span id="myHeader">            
                <div id="headerDesktopFirstSection" className={`container-fluid ${ sticky? 'sticky' : null}`}>
                    <div className="row">
                        <div className="col-md-3 logo">
                            <Link to="/"><img src={require('../../../assets/img/logo.png')} alt='logo' className="logo"/></Link>
                        </div>
                        <div className="col-md-2 pushDown relative">
                            <Link className="mydrop relative"><span className="green">Exams <img src={require('../../../assets/img/dropdown.svg')} alt='logo' className=""/></span>                                      
                                <div className="row dropdown">
                                   {categoryList()}                  
                                </div>                                                 
                            </Link>   
                        </div>
                        <div className="col-md-3  relative pushDown1 searchSection">
                            <input type="text" placeholder="Search eg. NECO English 2020" name="keyword" value={keyword} onChange={handleSearch} onBlur={handleCloseBox}/> <img src={require('../../../assets/img/search.svg')} alt='logo' className="search"/>
                            {
                                isLoading?
                                    <img src={require('../../../assets/img/loading.gif')} alt='logo' className="loader"/>
                                :null
                            }
                         
                            { showResult?
                            <ul> { mySearchResults() }  </ul>
                            :null
                            } 
                           
                        </div>
                        <div className="col-md-4 floatRight right pushDown">
                         { !isAuthenticated? <><Link onClick={toggle2} className="register login">Login</Link>  <Link onClick={toggle} className="register">Create Free Account</Link></>
                         :
                          <>
                           <div className="row">
                                <div className="col-3">
                                   
                                </div>
                                <div className="col-2">
                                    <Link><img src={require('../../../assets/img/notification.svg')} alt='notification' className="notification"/></Link>
                                </div>
                                <div className="col-5 boldFont1 padOff">
                                    { firstName } { lastName }
                                </div>
                                <div className="col-2 rr">
                                    <Link><span className="circleUp"></span>
                                        <div className="boldFont1 logout">
                                            <Link to="/histories" title="Goto dasboard">* Dashboard</Link>
                                            <Link to="/" onClick={handleLogout} title="logout">* Logout</Link>                                     
                                        </div>
                                    </Link>
                                </div>

                           </div>                          
                          </>                       
                         }    
                        </div>
                    </div>
                    {
                     privacyPage?
                        <div className="row privacySection">
                            <div className="col-md-3">    </div>
                            <div className="col-md-9">
                                <ul>
                                    <li className={`${privacyStatus ===2? 'green' : null}`}><Link onClick={handlePrivacySection.bind(this, 2)}>Privacy Policy</Link></li>
                                    <li className={`${privacyStatus ===1? 'green' : null}`}><Link onClick={handlePrivacySection.bind(this, 1)}>Terms of Service</Link></li>
                                </ul>
                            </div>
                        </div> 
                     : null
                    }
                    
                </div>  
                <div id="mobileHeader" className={`container ${ sticky? 'sticky' : null}`}>
                    <div className="row">
                        <div className="col-7">
                           <Link onClick={ handleMoreOptions.bind(null,'open') }><FontAwesomeIcon icon={faBars} /></Link>  &nbsp;&nbsp;&nbsp;&nbsp;<Link to="/"><img className="logo" src={require('../../../assets/img/logo.png')} alt='Logo'/></Link>
                        </div>
                        {/* <div className="col-1"></div> */}
                        <div className="col-5 floatRight right padOff">
                        { 
                         isAuthenticated?                    
                            <Link className="rr"><span className="circleUp">{firstName.substr(0,1)}</span>
                                 <div className="boldFont1 logout">
                                    <Link onClick={handleLogout}>Logout</Link>    
                                </div>                            
                            </Link>
                            : <> <Link onClick={toggle2}>Login</Link> | <Link onClick={toggle}>Sign Up</Link></>
                        }
                        </div>
                    </div> 
                    { 
                        footerMoreOptions?
                            <div className="container mobile footer1">            
                                <div className="row">
                                    <div className="col-12">
                                        <ul> 
                                            <li> <Link to="/" onClick={ handleMoreOptions.bind(null,'open') }>HOME </Link> </li>
                                            {isAuthenticated?  <li> <Link to="/histories" onClick={ handleCategoryNaviagtion.bind(null,1)}>EXAM HISTORY</Link> </li> : null}
                                            <li> <Link to="/category" onClick={ handleCategoryNaviagtion.bind(null,1)}>ALL CATEGORIES</Link> </li>  
                                            {isAuthenticated?  <li> <Link onClick={handleLogout}>LOGOUT </Link> </li> : null}                                                                        
                                        </ul>
                                    </div>              
                                </div>
                            </div>
                        : null
                        } 
                </div>          
            </span>
            <Modal isOpen={modal} toggle={toggle}>               
                <ModalHeader toggle={toggle}>&nbsp; <Link className="login register" onClick={handleAuthFormDisplay.bind(this,'login')}>Login</Link></ModalHeader>
                <ModalBody>                    
                    <div className="container-fluid signUp">
                        <div className="row">                          
                            <div className="col-12">
                                <div className="row">
                                    <div className="col-12 relative">
                                        <Link to="/"><img src={require('../../../assets/img/logo.png')} alt='logo' className="logo moveToFront"/></Link>
                                        {showMessage && error.id ==='REGISTER_SUCCESS'? <img src={require('../../../assets/img/ballon.gif')} alt='dance' className="ballonEmoji1"/> : null}
                                        <p className="p1 moveToFront">Create your profile</p> 
                                        <p>{showMessage ? <Alert color={showMessageClass}>{ error.msg.isArray ? error.msg.map(item => {return <>{item.msg}</> }): error.msg } {error.id ==='REGISTER_SUCCESS'? <><br/>You've successfully created an account on Exambly!</>:null}</Alert> : null}</p>
                                        <p className="p2 moveToFront">Create a profile to track and share your progress with your friends.</p>
                                    </div>
                                </div>
                                <form onSubmit={handleRegistration}>
                                    <div className="row">                          
                                        <div className="col-12">
                                            <input type="text" placeholder="First Name" name="firstname" value={firstname} onChange={handleChange} required/>
                                        </div>                                                  
                                    </div>
                                    <div className="row">                          
                                        <div className="col-12">
                                            <input type="text" placeholder="Surname" name="lastname" value={lastname} onChange={handleChange} required/>
                                        </div>                                                  
                                    </div>
                                    <div className="row">                          
                                        <div className="col-12">
                                            <input type="email" placeholder="Email" name="email" value={email} onChange={handleChange} required/>
                                        </div>                                                  
                                    </div>
                                    <div className="row">                          
                                        <div className="col-12">
                                            <input type="text" placeholder="Phone Number" name="mobile" value={mobile} onChange={handleChange} required/>
                                        </div>                                                  
                                    </div>
                                    <div className="row">                          
                                        <div className="col-12">
                                            <input type="password" placeholder="Password" name="password" value={password} onChange={handleChange} required/>
                                        </div>                                                  
                                    </div>
                                    <div className="row">                          
                                        <div className="col-12">
                                            <input type="password" placeholder="Confirm Password" name="confirmPassword" value={confirmPassword} onChange={handleChange} required/>
                                        </div>                                                  
                                    </div>
                                    <div className="row">                          
                                        <div className="col-12">
                                            <input type="submit" value="Sign Up"/>
                                        </div>                                                  
                                    </div>
                                </form>
                                <div className="row push">                          
                                    <div className="col-5">
                                        <hr/>
                                    </div> 
                                    <div className="col-2  boldFont center">
                                        OR
                                    </div> 
                                    <div className="col-5">
                                        <hr/>
                                    </div>                                                  
                                </div>
                                <div className="row push">                          
                                    <div className="col-6 google">
                                        <GoogleLogin
                                            clientId="317940776433-k6e7hhdqtoq87js1f76oa5on6njm7909.apps.googleusercontent.com"
                                            render={renderProps => (
                                            <button onClick={renderProps.onClick} disabled={renderProps.disabled}><span><img src={require('../../../assets/img/Google__G__Logo.svg')} alt='google'/> Google</span></button>
                                            )}
                                            buttonText="Login"
                                            onSuccess={googleSignupResponse}
                                            onFailure={onFailure}
                                            cookiePolicy={'single_host_origin'}
                                        />                                 
                                    </div>                                
                                    <div className="col-6 facebook">                                
                                        <FacebookLogin
                                            appId='264373944539555'
                                            autoLoad={false}
                                            fields="name,email,picture"
                                            isMobile={false}
                                            redirectUri ='https://www.exambly.com/'
                                            callback={facebookSignupResponse} 
                                            render={renderProps => (
                                                <button onClick={renderProps.onClick}><span><img src={require('../../../assets/img/F_icon_reversed.svg')} alt='google'/> Facebook</span></button>
                                            )}
                                        />
                                    
                                    </div>                                                      
                                </div>
                                <div className="row push">                          
                                    <div className="col-12 center">
                                    <p>  By signing in to Exambly, you agree to our <br className="desktopOnly"/> <Link className="bold" onClick={handlePrivacySection.bind(this, 1)} to="/privacy_policy">Terms</Link> and <Link className="bold" onClick={handlePrivacySection.bind(this, 2)} to="/privacy_policy">Privacy Policy</Link>.</p>
                                    </div>                                                                         
                                </div>                      
                            </div>                                      
                        </div>
                    </div>     
                </ModalBody> 
            </Modal> 
            <Modal isOpen={modal2} toggle={toggle2}>               
                <ModalHeader toggle={toggle2}>&nbsp; <Link className="register"  onClick={handleAuthFormDisplay.bind(this,'signup')}>Create Account</Link></ModalHeader>
                <ModalBody>                    
                <div className="container-fluid login">
                    <div className="row">                      
                        <div className="col-12">
                            <div className="row">
                                <div className="col-12">
                                    <Link to="/"><img src={require('../../../assets/img/logo.png')} alt='logo' className="logo"/></Link>
                                    <p className="p1">Login</p>
                                    <p>{showMessage ? <Alert color={showMessageClass}>{ error.msg.isArray ? error.msg.map(item => {return <>{item.msg} <br/></> }): error.msg }</Alert> : null}</p>
                                    <p className="p2">Login to see, track and share your progress with your friends.</p>
                                </div>
                            </div>  
                            <form onSubmit={handleLogin}>                   
                                <div className="row">                          
                                    <div className="col-12">
                                        <input type="email" placeholder="Email" name="email" value={email} onChange={handleChange} required/>
                                    </div>                                                  
                                </div>                      
                                <div className="row">                          
                                    <div className="col-12">
                                        <input type="password" placeholder="Password" name="password" value={password} onChange={handleChange} required/>
                                        <Link onClick={toggle3} className="forgotPassword">Forgot Password?</Link>
                                    </div>                                                  
                                </div>                      
                                <div className="row relative">                          
                                    <div className="col-12">
                                        <input type="submit" value="Login"/>                               
                                    </div>                                                  
                                </div>
                            </form>    
                            <div className="row push">                          
                                <div className="col-5">
                                    <hr/>
                                </div> 
                                <div className="col-2  boldFont center">
                                    OR
                                </div> 
                                <div className="col-5">
                                    <hr/>
                                </div>                                                  
                            </div>
                            <div className="row push">                          
                                <div className="col-6 google">
                                    <GoogleLogin
                                        clientId="317940776433-k6e7hhdqtoq87js1f76oa5on6njm7909.apps.googleusercontent.com"
                                        render={renderProps => (
                                        <button onClick={renderProps.onClick} disabled={renderProps.disabled}><span><img src={require('../../../assets/img/Google__G__Logo.svg')} alt='google'/> Google</span></button>
                                        )}
                                        buttonText="Login"
                                        onSuccess={googleLoginResponse}
                                        onFailure={onFailure}
                                        cookiePolicy={'single_host_origin'}
                                    />                                 
                                </div> 
                            
                                <div className="col-6 facebook">                                
                                    <FacebookLogin
                                        appId='264373944539555'
                                        autoLoad={false}
                                        isMobile={false}
                                        redirectUri ='https://www.exambly.com/'
                                        fields="name,email,picture"
                                        callback={facebookLoginResponse} 
                                        render={renderProps => (
                                            <button onClick={renderProps.onClick}><span><img src={require('../../../assets/img/F_icon_reversed.svg')} alt='google'/> Facebook</span></button>
                                          )}
                                    />
                                 
                                </div>                                                  
                            </div>
                            <div className="row push">                          
                                <div className="col-12 center">
                                <p>  By signing in to Exambly, you agree to our <br className="desktopOnly"/> <Link className="bold" onClick={handlePrivacySection.bind(this, 1)}  to="/privacy_policy">Terms</Link> and <Link className="bold" onClick={handlePrivacySection.bind(this, 2)} to="/privacy_policy">Privacy Policy</Link>.</p>
                                </div>                                                                         
                            </div>                      
                        </div>                                 
                    </div>
                </div>     
                </ModalBody> 
            </Modal> 
            <Modal isOpen={modal3} toggle={toggle3}>               
                <ModalHeader toggle={toggle3}>&nbsp;</ModalHeader>
                <ModalBody>                    
                    <div className="container-fluid forgotPassword">
                        <div className="row">
                            <div className="col-12">
                                <div className="row">
                                    <div className="col-12">
                                        <Link to="/"><img src={require('../../../assets/img/logo.png')} alt='logo' className="logo"/></Link>
                                        <p className="p1">Forgot Password</p>
                                        <p>{showMessage ? <Alert color={showMessageClass}>{ error.msg.isArray ? error.msg.map(item => {return <>{item.msg} <br/></> }): error.msg }</Alert> : null}</p>
                                        <p className="p2 center">Enter your known email to reset your password</p>
                                    </div>
                                </div> 
                                <form onSubmit={handleForgotPassword}>                         
                                    <div className="row">                          
                                        <div className="col-12">
                                            <input type="email" placeholder="Email" name="email" value={email} onChange={handleChange} required/>
                                        </div>                                                  
                                    </div>    
                                    <div className="row relative">                          
                                        <div className="col-12">
                                            <input type="submit" value="Submit"/>                               
                                        </div>                                                  
                                    </div>     
                                </form>                   
                                <div className="row push">                          
                                    <div className="col-12 center">
                                    <p>  By signing in to Exambly, you agree to our <br className="desktopOnly"/> <Link className="bold" to="/privacy_policy">Terms</Link> and <Link className="bold" to="/privacy_policy">Privacy Policy</Link>.</p>
                                    </div>                                                                         
                                </div>                      
                            </div>                                
                        </div>
                    </div>     
                </ModalBody> 
            </Modal> 
            <Modal isOpen={modal4} toggle={toggle4}>               
                <ModalHeader toggle={toggle4}>&nbsp; </ModalHeader>
                <ModalBody>                    
                    <div className="container-fluid signUp">
                        <div className="row">                          
                            <div className="col-12">
                                <div className="row">
                                    <div className="col-12 relative">
                                        <Link to="/"><img src={require('../../../assets/img/logo.png')} alt='logo' className="logo moveToFront"/></Link>
                                        {showMessage && error.id ==='REGISTER_SUCCESS'? <img src={require('../../../assets/img/ballon.gif')} alt='dance' className="ballonEmoji1"/> : null}
                                        <p className="p1 moveToFront">&nbsp;</p> 
                                        <p>{showMessage ? <Alert color={showMessageClass}>{ error.msg.isArray ? error.msg.map(item => {return <>{item.msg}</> }): error.msg } {error.id ==='REGISTER_SUCCESS'? <><br/>You've successfully created an account on Exambly!</>:null}</Alert> : null}</p>
                                    </div>
                                </div>
                                                     
                            </div>                                      
                        </div>
                    </div>     
                </ModalBody> 
            </Modal> 
         
        </>      
	);
};

Header.propTypes= {   
    inputChange: PropTypes.func.isRequired,  
    registerUser: PropTypes.func.isRequired, 
    loginUser: PropTypes.func.isRequired, 
    loadQuestions: PropTypes.func.isRequired,     
}

const mapStateToProps = state => ({  
    sticky: state.auth.sticky,
    footerMoreOptions: state.auth.footerMoreOptions,
    WAEC: state.auth.WAEC,
    JAMB: state.auth.JAMB,
    redirect: state.auth.redirect,
    location: state.auth.location, 
    email: state.auth.email,
    password: state.auth.password,    
    firstname: state.auth.firstname,
    lastname: state.auth.lastname,
    mobile: state.auth.mobile,    
    confirmPassword: state.auth.confirmPassword,
    showMessage: state.auth.showMessage,
    showMessageClass: state.auth.showMessageClass,
    error:  state.error,
    isAuthenticated: state.auth.isAuthenticated,
    firstName: state.auth.firstName,
    lastName: state.auth.lastName,
    logout: state.auth.logout,
    keyword: state.auth.keyword,
    isLoading: state.auth.isLoading,
    searchResults: state.auth.searchResults,
    showResult: state.auth.showResult,
    privacyStatus: state.auth.privacyStatus,
    privacyPage : state.auth.privacyPage,
    showLogin: state.auth.showLogin,
    showSignup: state.auth.showSignup,
    categories: state.auth.categories
})
export default connect(mapStateToProps, {inputChange, registerUser, clearErrors, loginUser, resetPassword, search, loadQuestions})(Header);