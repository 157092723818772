import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import landingPage from "../screens/landingPage/landingPage.component";
import categoryPage from "../screens/category/category.component";
import subjectPage from "../screens/subject/subject.component";
import homepage from "../screens/homepage/homePage.component";
import histories from "../screens/histories/histories.component";
import exam from "../screens/exam/exam.component";
import review from "../screens/review/review.component";
import changePassword from "../screens/auth/changePassword/changePassword.component";
import privacy from "../screens/privacyPolicy/policies.component";
import instructions from "../screens/instructions/instructions.component";
import share from "../screens/share/share.component";
import Analytics from "../../analytics";

const MyNav = props => {
	return (
		<Router>           	
	    	<Switch>
				<Route exact path='/' component={landingPage} />  
				<Route path='/category' component={categoryPage} /> 	
				<Route path='/subject' component={subjectPage} /> 
				<Route path='/home' component={homepage} /> 	
				<Route path='/histories' component={histories} /> 	
				<Route path='/exam' component={exam} /> 
				<Route path='/review' component={review} /> 
				<Route path='/change-password' component={changePassword} /> 	
				<Route path='/privacy_policy' component={privacy} />  
				<Route path='/instructions' component={instructions} />   
				<Route path='/share' component={share} />       						
			</Switch>	
			<Analytics/>		
		</Router>
	);
};
export default MyNav;