import React, { useEffect, useRef } from "react";
import './css/style.css';
import Header from './../../includes/header/header.component';
import Sidebar from './../../includes/sidebar.component';
import History from './../../includes/history.component';
import { connect } from 'react-redux';
import PropTypes from "prop-types";
import { getHistory } from './../../../redux/actions/authActions';

const HistoryScreen = props => {  
    const mounted = useRef();    
    useEffect(()=>{
        if (!mounted.current) {
            // do componentDidMount logic
            mounted.current = true;
            window.scrollTo(0, 0);   
            props.getHistory();              
        } else {
            // do componentDidUpdate logic          
          } 	       
    })   
    
    const {        
        histories
    } = props;
    const calculateAverage = (sum, item) =>{
        return sum + +item.score
    }
    const historyList = () => {         
        if(histories && histories.length){
            return histories.map((item) => { 
                return   <History
                            category={item.exam_name}
                            subject={item.subject}                               
                            questionNumber={+item.no_correct_questions + +item.no_wrong_questions + +item.no_skipped_questions} 
                            timeLength={60} 
                            correct={item.no_correct_questions} 
                            wrong={item.no_wrong_questions} 
                            skipped={item.no_skipped_questions} 
                            percentage={item.score} 
                            remark={item.remark} 
                            velocity={item.time_spent} 
                            date={item.date} 
                        />                         
           })
        }else{
           return <h6>You are yet to attempt any test or exam. Navigate to the exam page to begin your learning experience.</h6>
        }        
    }
	return (        
		<>          
           <Header/>          
           <div className="container-fluid history">
                <div className="row">
                    <Sidebar/>
                    <div className="col-md-10 afterNav">
                        <div className="row box1">
                            <div className="col-md-12 box">
                              {histories?  <h2>Progress<span>Total Tests Taken: {histories.length}<br/>Average Pass Rate: {Math.round((histories.reduce(calculateAverage,0))/histories.length)}%</span></h2>:null} 
                            </div>
                        </div>
                        <div className="row box3">
                            <div className="col-md-12 box4">
                                {historyList()}                                     
                            </div>                            
                        </div>
                     </div>   
                 </div>
            </div>
        </>
	);
};

HistoryScreen.propTypes= {   
    getHistory: PropTypes.func.isRequired,  
}

const mapStateToProps = state => ({   
    histories: state.auth.histories  
})


export default connect(mapStateToProps, {getHistory})(HistoryScreen);