import React from "react";
import { Link } from "react-router-dom";
import { connect } from 'react-redux';
import PropTypes from "prop-types";
import { loadSubjects, inputChange } from './../../redux/actions/authActions';

const Box = props => {
    const handleGotoSubjects = (e) =>{ 
        e.preventDefault()
        props.inputChange('selectedCategoryImg', props.image);
        props.inputChange('selectedCategory', props.title);
        props.loadSubjects(props.categoryId, props.examId);
    }    
    return (
        <div className="col-4">
            <Link onClick={ handleGotoSubjects }>    
                <div className="row">
                    <div className="col-5 paddingRightOff">
                        <img src={props.image} alt='' className="fullWidth item"/>
                    </div>
                    <div className="col-7 paddingLeftOff push2 paddingRightOff">
                        {props.title}
                    </div>
                </div>
            </Link>   
        </div>     
	);
};
Box.propTypes= {   
    loadSubjects: PropTypes.func.isRequired,
    inputChange: PropTypes.func.isRequired       
}

export default connect(null, {loadSubjects, inputChange})(Box);