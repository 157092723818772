import React, { useEffect, useRef } from "react";
import './css/style.css';
import { Link } from "react-router-dom";
import { connect } from 'react-redux';
import PropTypes from "prop-types";
import { inputChange, getResult } from '../../../redux/actions/authActions';
import Header from '../../includes/header/header.component';
import Sidebar from '../../includes/sidebar.component';
import { PieChart } from 'react-minimal-pie-chart';
import queryString from "query-string";

const Share = props => {  
    const mounted = useRef(); 

    const {       
        showResult,
        certificateName,
        result        
    } = props;

    useEffect(()=>{
        if (!mounted.current) {
            // do componentDidMount logic
            mounted.current = true;
            window.scrollTo(0, 0);
            props.inputChange('redirect', false);	

            let uid = null;
            let uname = null;
            const parsed = queryString.parse(props.location.search);
    
            if(Object.keys(parsed).length){
                uid = parsed.uid;
                uname = parsed.uname; 
            }
            
            if(uid && uname){
                uname = uname.replace(/_/gmi, " ");  
                props.inputChange('certificateName', uname);
                props.getResult(uid) 
            }
       
        } else {
            // do componentDidUpdate logic          
          } 	       
    })  
    const handleGrade = (getEmoji=false, getDefinition=false) => {         
        let average = result.score
        let grade = 'Unknown';
        let definition = 'Unknown Comment';
        let remark = 'Unknown Remark';
        let emoji = <img src={require('./../../../assets/img/f9.gif')} alt='logo' className="myBadge7"/>;
        
        if(average >= 75){
             grade = 'A1'
             definition = 'Excellent'
             remark = 'Congrats, genius, that was excellent! Your Test Grade Result is A1, and we’re super proud of you. Practice more to remain ahead of the pack!'
             emoji = <img src={require('../../../assets/img/a1.gif')} alt='logo' className="myBadge7"/>;
        }else if(average >= 70){
             grade = 'B2'
             definition = 'Very Good'
             remark = 'Awesome! Your Test Grade Result is B2. You’re very smart and we’re rooting for you! Practice more to stay ahead of the pack!'
             emoji = <img src={require('../../../assets/img/b2.gif')} alt='logo' className="myBadge3"/>;
        }else if(average >= 65){
             grade = 'B3'
             definition = 'Good'
             remark = 'Great! Your Test Grade Result is B3. You did very well and can do even better, with more practice.'
             emoji = <img src={require('../../../assets/img/b3.gif')} alt='logo' className="myBadge3"/>;
        }else if(average >= 60){
             grade = 'C4'
             definition = 'Credit'
             remark = 'Very good! Your Test Grade Result is C4. You did well and can do much better, with more practice. '
             emoji = <img src={require('../../../assets/img/c4.gif')} alt='logo' className="myBadge3"/>;
        }else if(average >= 55){
             grade = 'C5'
             definition = 'Credit'
             remark = 'Good! Your Test Grade Result is C5. You did quite well and can do even better, with more practice. We believe in you.'
             emoji = <img src={require('../../../assets/img/c5.gif')} alt='logo' className="myBadge3"/>;
        }else if(average >= 50){
             grade = 'C6'
             definition = 'Credit'
             remark = 'Fair attempt! Your Test Grade Result is C6. You did fairly well and you can improve, with more practice. '
             emoji = <img src={require('../../../assets/img/c6.gif')} alt='logo' className="myBadge3"/>;
        }else if(average >= 45){
             grade = 'D7'
             definition = 'Pass'
             remark = 'Oops! Your Test Grade Result is D7. To ace your exam, please practice more. We strongly believe you can do better, with more practice.'
             emoji = <img src={require('../../../assets/img/d7.gif')} alt='logo' className="myBadge3"/>;
        }else if(average >= 40){
             grade = 'E8'
             definition = 'Pass'
             remark = 'Oops! Your Test Grade Result is E8. To ace your exam, please practice more. We strongly believe you can do better, with more practice. Let’s do this!'
             emoji = <img src={require('../../../assets/img/e8.gif')} alt='logo' className="myBadge3"/>;
        }else{
             grade = 'F9'
             definition = 'Fail'
             remark = 'Ouch! We strongly advice you study better and retake the test. We know you can do a lot better, with more practice. Yes, it is possible!'
             emoji = <img src={require('../../../assets/img/f9.gif')} alt='logo' className="myBadge3"/>;
        }
        if(getEmoji){
            return emoji
        }
        if(getDefinition){
            return definition
        }
        return remark;
    }
    const handleDownload = ()=>{
        window.location.href = 'https://play.google.com/store/apps/details?id=com.exambly'; 
    }
  
    const handleCloseBox = e =>{   
        e.preventDefault();
        if(showResult){
            props.inputChange('showResult', false);	 
        }         
    }
       
	return (        
		<>             
           <Header/>
           <div className="container-fluid share" onClick={handleCloseBox}>
                <div className="row">
                   <Sidebar/>
                   <div className="col-md-10 afterNav">
                        <div className="row">
                            <div className="col-md-12">
                                <img src={require('../../../assets/img/logo.png')} className="shareLogo" alt="correct"/>
                            </div>
                        </div>  
                        <div className="row">
                            <div className="col-md-12">
                                <img src={require('../../../assets/img/award.png')} className="award" alt="correct"/>
                            </div>
                        </div>  
                        <div className="row push1">
                            <div className="col-md-12">
                               <h2 className="boldFont center">RESULT ANALYSIS</h2> 
                               <h6 className="center">{result.exam_name} {result.subject} {result.year} Exam Practice - {result.created_at}</h6>
                               <h5 className="boldFont center">{certificateName}</h5>
                               <h1 className="boldFont center green">{result.score}%</h1>
                               <p className="push2 center">Dear {certificateName}, {handleGrade()}</p>
                            </div>
                        </div> 
                        <div className="row push2">
                            <div className="col-md-12">
                              <p className="title">Metrics</p>
                            </div>
                        </div>    
                        <div className="row">
                            <div className="col-md-6 myChart">
                                <PieChart
                                    data={[
                                        { title: 'Correct Answers', value: +result.no_correct_questions, color: '#00AD8A' },
                                        { title: 'Skipped', value: +result.no_skipped_questions, color: '#FE9923' },
                                        { title: 'Wrong Answers', value: +result.no_wrong_questions, color: '#FD003A' },
                                    ]}
                                    radius={20}                                
                                    radius={PieChart.defaultProps.radius - 6}
                                    segmentsStyle={{ transition: 'stroke .3s', cursor: 'pointer' }}
                                />
                            </div>
                            <div className="col-md-6 push3">
                                <div className="row">
                                    <div className="col-md-12">
                                        <span><img src={require('../../../assets/img/green.png')} className="dot" alt="correct"/> Correct: {result.no_correct_questions} </span><br/><br/>
                                        <span><img src={require('../../../assets/img/red.png')} className="dot" alt="correct"/> Incorrect: {result.no_wrong_questions} </span><br/><br/>
                                        <span><img src={require('../../../assets/img/yellow.png')} className="dot" alt="correct"/> Skipped: {result.no_skipped_questions} </span>   
                                    </div>
                                </div>
                            </div>
                        </div> 
                        <div className="row push2">
                            <div className="col-md-12">
                              <p className="title title2">Grade</p>
                            </div>
                        </div>  
                        <div className="row scoreSection">
                            <div className="col-6">
                                <span><img src={require('../../../assets/img/score.png')} className="dot dot1" alt="correct"/> Score </span> 
                            </div>
                            <div className="col-6">
                                <span>{result.no_correct_questions} out of {+result.no_correct_questions + +result.no_skipped_questions + +result.no_wrong_questions}</span> 
                            </div>
                        </div>      
                        <div className="row scoreSection">
                            <div className="col-6">
                                <span><img src={require('../../../assets/img/percentage.png')} className="dot dot1" alt="correct"/> Percentage </span> 
                            </div>
                            <div className="col-6">
                                <span>{result.score}%</span> 
                            </div>
                        </div>  
                        <div className="row scoreSection">
                            <div className="col-6">
                                <span><img src={require('../../../assets/img/score.png')} className="dot dot1" alt="correct"/> Remark </span> 
                            </div>
                            <div className="col-6">
                                <span>{handleGrade(false, true)}</span> 
                            </div>
                        </div>   
                        <div className="row push33">
                            <div className="col-md-12">
                                <Link onClick={handleDownload} className="android register floatRight">Download Android App </Link>    <Link to="/" className="register ios floatRight">Download IOS App </Link>                                 
                            </div>
                        </div>                                     
                   </div>
                </div>
           </div>     
        </>
	);
};

Share.propTypes= {   
    inputChange: PropTypes.func.isRequired,  
}

const mapStateToProps = state => ({    
    showResult: state.auth.showResult,
    certificateName: state.auth.certificateName,  
    result: state.auth.result
})
export default connect(mapStateToProps, {inputChange, getResult})(Share);