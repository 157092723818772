import React  from "react";
import { Link } from "react-router-dom";
import { connect } from 'react-redux';
import { inputChange } from './../../../redux/actions/authActions';
import "./css/style.css";


const Footer = props => {
    const {       
        averagePassRate,
        completedTest,
        noOfStudents     
    } = props; 

    const handlePrivacySection = (category, e) =>{               
        props.inputChange('privacyStatus', category);
    }
    function numberWithCommas(x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
	return (		
        <span id="footer">
            <div className='container-fluid'>
                <div className="row r1">
                    <div className="col-md-4">
                        <h6>Why Exambly.com</h6>
                        <p className="p1">Exambly offers free exam practice for Africans to ace any examination successfully.<br/><br/>Preparing for exams with Exambly is fun as you earn points for correct answers, stay motivated with rewards, learn faster, and receive instant results. <br/><br/><span className="boldFont"></span></p>
                    </div>
                    <div className="col-md-2 partTwo">
                        <h6>About</h6>
                        <ul>
                            <li><Link>Blog</Link></li>
                            <li><Link>Media</Link></li>
                            <li><Link>Careers</Link></li>
                            <li><Link>Our Team</Link></li>
                            <li><Link>Community</Link></li>
                            <li><Link>Partnership</Link></li>
                        </ul>
                    </div>
                    <div className="col-md-3 partThree">
                        <h6>Contact</h6>
                        <ul>
                            <li><img className="logo1" src={require('../../../assets/img/Email.svg')} alt='Logo'/> &nbsp;&nbsp;hello@Exambly.com</li>
                            <li className="num"><img className="logo2" src={require('../../../assets/img/Phone_Call.png')} alt='Logo'/>+ 234 802 785 5262<br/> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; + 234 805 154 4949</li>   
                            <li><img className="logo1" src={require('../../../assets/img/Location PIN.svg')} alt='Logo'/> &nbsp;&nbsp;6 Gbemisola Street,<br/> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Allen Avenue, Ikeja,<br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Lagos, Nigeria.</li>                      
                        </ul>
                    </div>
                    <div className="col-md-3">
                        <h6>Key Stats</h6>
                        <p>Tests Completed</p>
                        <p className="p2">{numberWithCommas(completedTest)}</p>

                        <p>Customers</p>
                        <p className="p2">{numberWithCommas(noOfStudents)}</p>

                        <p>Avg. Pass Rate</p>
                        <p className="p2">{averagePassRate? averagePassRate : 0}%</p>                       
                    </div>
                </div>
                <div className="row r2">
                    <div className="col-md-3">
                        <img className="logo" src={require('../../../assets/img/logo1.png')} alt='Logo'/>
                    </div>
                    <div className="col-md-5 listP">
                        <ul>
                            <li><Link onClick={handlePrivacySection.bind(this, 2)} to="/privacy_policy">Privacy Policy</Link></li>
                            <li><Link onClick={handlePrivacySection.bind(this, 2)} to="/privacy_policy">Copyright</Link></li>   
                            <li><Link onClick={handlePrivacySection.bind(this, 1)} to="/privacy_policy">Terms of Service</Link></li>                      
                        </ul>
                    </div>
                    <div className="col-md-4 listP1 floatRight right">
                        <ul>
                            <li><a href="http://facebook.com/exambly" target="_blank"  rel="noopener noreferrer"><img className="logo5" src={require('../../../assets/img/Facebook1.svg')} alt='Logo'/></a></li>
                            <li><a href="https://twitter.com/ExamblyApp" target="_blank"  rel="noopener noreferrer"><img className="logo5" src={require('../../../assets/img/Twitter.svg')} alt='Logo'/></a></li>  
                            <li><a href="http://instagram.com/theexamblyapp" target="_blank"  rel="noopener noreferrer"><img className="logo5" src={require('../../../assets/img/Instagram.svg')} alt='Logo'/></a></li>       
                            <li><a href="https://www.youtube.com/channel/UC_BnnokJom1DWipMl0oSxWA" target="_blank"  rel="noopener noreferrer"><img className="logo5" src={require('../../../assets/img/Youtube.svg')} alt='Logo'/></a></li>                  
                        </ul>
                    </div>
                </div>
            </div>  
        </span>       
	);
};

const mapStateToProps = state => ({  
    averagePassRate: state.auth.averagePassRate,
    completedTest: state.auth.completedTest,
    noOfStudents: state.auth.noOfStudents 
})
export default connect(mapStateToProps, { inputChange })(Footer);