import API from "./../../assets/js/api"
import { returnErrors } from "./errorActions";

import { 
    INPUT_CHANGE,
    LOAD_SUBJECTS_SUCCESS,
    LOAD_SUBJECTS_FAILURE,
    CHANGE_DISPLAY_SUBJECTS,
    LOAD_QUESTIONS_SUCCESS,
    LOAD_QUESTIONS_FAILURE,
    SAVE_USER_ANSWER,
    UPDATE_QUESTION_TAG,
    FILTER_SUBJECTS,
    REGISTER_SUCCESS,
    REGISTER_FAILURE,
    AUTH_SUCCESS,
    AUTH_FAILURE,
    CLEAR_FORM, 
    LOGIN_SUCCESS,
    LOGIN_FAILURE, 
    REDIRECT_TO_REVIEW,
    GET_STATISTICS_SUCCESS,
    GET_STATISTICS_FAILURE,
    POPULATE_SUBMITTED_ANSWER,
    SUBMIT_RESULT_SUCCESS,
    SUBMIT_RESULT_FAILURE,
    RESET_PASSWORD_SUCCESS,
    RESET_PASSWORD_FAILURE,
    CHANGE_PASSWORD_SUCCESS,
    CHANGE_PASSWORD_FAILURE,
    SEARCH_SUCCESS,
    SEARCH_FAILURE,
    ISLOADING,
    LOADING_COMPLETE,
    LOAD_RESULT_HISTORIES_SUCCESS,
    LOAD_RESULT_HISTORIES_FAILURE,  
    LOAD_CATEGORIES_FAILURE, 
    LOAD_CATEGORIES_SUCCESS,
    FLAG_QUESTION_SUCCESS,
    FLAG_QUESTION_FAILURE,
    GET_RESULT_FAILURE,
    GET_RESULT_SUCCESS 
   } 
    from './types';    
    
export const inputChange = (name, value) => async dispatch => {
    try {       	
        dispatch({
            type: INPUT_CHANGE,
            payload:{
                name:name,
                value:value
            }
        }) 

        if(name === 'category'){
            dispatch({
                type: CHANGE_DISPLAY_SUBJECTS,
                payload:value
            })    
        }

        if(name === 'filterCategory'){
            dispatch({
                type: FILTER_SUBJECTS,
                payload:value
            })    
        }
        
    } catch (error) {
        console.error(error);
    }    

}

export const loadCategories = () => async dispatch => {    
    try { 
        document.body.classList.add('loading-indicator');	
        const result = await API.loadExams();
        if(result.data.error === false){
            dispatch({
                type: LOAD_CATEGORIES_SUCCESS,
                payload:result.data.exams
            })           
        }else{
            dispatch(returnErrors('Error fetching categories', 400,'LOAD_CATEGORIES_FAILURE'));
            dispatch({
                type: LOAD_CATEGORIES_FAILURE      
            })
        }
        document.body.classList.remove('loading-indicator');       
    } catch (err) {  
        console.log(err)  
        document.body.classList.remove('loading-indicator');      
        dispatch(returnErrors('Server Error Occured', 400,'LOAD_CATEGORIES_FAILURE'));
        dispatch({
            type: LOAD_CATEGORIES_FAILURE            
        })  
    }
}

export const loadSubjects = (categoryID, examId) => async dispatch => {    
    try {   
        document.body.classList.add('loading-indicator');  
        let result
        if(categoryID === '3'){ 
            dispatch({
                type: INPUT_CHANGE,
                payload:{
                    name: 'subjectTag',
                    value: 'School' 
                }
            })  
            result = await API.loadSchools(); 
        }else{   
            dispatch({
                type: INPUT_CHANGE,
                payload:{
                    name: 'subjectTag',
                    value: 'Subject' 
                }
            })      
            result = await API.loadSubjects(examId); 
        }      

        if(result.data.error === false){           
            dispatch({
                type: LOAD_SUBJECTS_SUCCESS,
                payload:result.data.subjects  
            })                     
        }else{
            dispatch({
                type: LOAD_SUBJECTS_FAILURE           
            })	
        } 

        document.body.classList.remove('loading-indicator');
    } catch (err) {       
        document.body.classList.remove('loading-indicator');  
        dispatch(returnErrors('Server Error Occured', 400,'LOAD_SUBJECTS_FAILURE'));         
        dispatch({
            type: LOAD_SUBJECTS_FAILURE           
        })	
    }
}

export const flagQuestion = data => async dispatch => {    
    try {      
        const result = await API.flagQuestion(data); 
        if(result.data.error === false){
            dispatch({
                type: FLAG_QUESTION_SUCCESS
            })         
            dispatch(returnErrors(result.data.message, 200,'FLAG_QUESTION_SUCCESS')); 
        }else{
            dispatch(returnErrors(result.data.message, 400,'FLAG_QUESTION_FAILURE'));
            dispatch({
                type: FLAG_QUESTION_FAILURE     
            })
        }         
    } catch (err) {    
        dispatch(returnErrors('Server Error Occured', 400,'FLAG_QUESTION_FAILURE'));
        dispatch({
            type: FLAG_QUESTION_FAILURE          
        })  
    }
}

export const loadQuestions = (subjectId) => async dispatch => {    
    try {   
        document.body.classList.add('loading-indicator');	   
        const result = await API.loadQuestions(subjectId);
        let questions = [];  
        let questionTags= []; 
        let questionTime=60; 
        let theSubjectId=-1;        
        let motivations =[];

        if(result.data.error === false){
            theSubjectId = result.data.subject_details.subject_id
            questions = result.data.questions;
            let questionLength =questions.length;
            for(let i =0; i<questionLength; i++){
                questionTags.push(1)
            }   
            questionTime = result.data.subject_details.duration; 
            questionTime = questionTime * 1000 * 60;   
            motivations =  result.data.motivations                 
        }          
      
        dispatch({
            type: LOAD_QUESTIONS_SUCCESS,
            payload:{
                questions,
                questionTags,
                questionTime,
                theSubjectId,
                motivations               
            }       
        })    
       
        document.body.classList.remove('loading-indicator');      
   
    } catch (err) {       
        document.body.classList.remove('loading-indicator');         
        dispatch({
            type: LOAD_QUESTIONS_FAILURE          
        })	
    }
}

export const saveUserAnswer = (value) => async dispatch => {
    try {       	
        dispatch({
            type: SAVE_USER_ANSWER,
            payload:{              
                value:value
            }
        }) 

        let status = null;
        if(value === -1){
            status=3
        }else{
            status=2 
        }

        dispatch({
            type: UPDATE_QUESTION_TAG,
            payload:{              
                value:status
            }
        }) 
        
    } catch (error) {
        console.error(error);
    }    

}

export const populateSubmittedAnswer = (answer) => async dispatch => {
    try {       	
        dispatch({
            type: POPULATE_SUBMITTED_ANSWER,
            payload:{              
                value:answer
            }
        }) 
        
    } catch (error) {
        console.error(error);
    }
}

export const registerUser = (user, google=false, facebook=false) => async (dispatch, getState) => {    
    try {          
       
        if(user.password !== user.confirmPassword && !google && !facebook){
            dispatch(returnErrors('Confirm password does not match', 400,'REGISTER_FAILURE'));
            dispatch({
                type: REGISTER_FAILURE          
            })
        }else{
            document.body.classList.add('loading-indicator');
            let result = null;
            if(google){
                result = await API.socialLoginGoogle(user); 
            }else if(facebook){
                result = await API.socialLoginFacebook(user); 
            }else{
                result = await API.registerUser(user);  
            }
            
                       
            if(result.data.error === false){
                dispatch({
                    type: REGISTER_SUCCESS, 
                    payload:result.data.user        
                })
                dispatch({
                    type: CLEAR_FORM           
                })    
                dispatch(returnErrors(`Congratulations, ${result.data.user.firstname}!`, 200,'REGISTER_SUCCESS'));  
                const {redirectToReview} = getState().auth;
                if(redirectToReview){
                    dispatch({
                        type: REDIRECT_TO_REVIEW         
                    })
                }    
            }else{
                dispatch(returnErrors(result.data.message, 400,'REGISTER_FAILURE'));
                dispatch({
                    type: REGISTER_FAILURE          
                })
            }  
            document.body.classList.remove('loading-indicator');       
         }
   
    } catch (err) {       
        document.body.classList.remove('loading-indicator'); 
        dispatch(returnErrors('Server Error Occured', 400,'REGISTER_FAILURE'));
        dispatch({
            type: REGISTER_FAILURE          
        })
    }
}

export const loadUser = () => async dispatch => {    
    try {   
        const token = localStorage.getItem("token");
        const firstname = localStorage.getItem("firstName");
        const lastname = localStorage.getItem("lastName"); 
        const userId = localStorage.getItem("userId"); 
       
        if(token && firstname && lastname){
            dispatch({
                type: AUTH_SUCCESS,
                payload:{
                    'auth_token':token,
                    'firstname':firstname,
                    'lastname':lastname,
                    'user_id':userId
                }                       
            })
        }else{
            document.body.classList.remove('loading-indicator');
            dispatch({
                type: AUTH_FAILURE
            })
        }           
        
    } catch (err) {        
        dispatch({
            type: AUTH_FAILURE            
        })	
    }
}

export const loginUser = (user, google=false, facebook=false) => async (dispatch, getState) => {    
    try { 
        document.body.classList.add('loading-indicator');
        let result = null;
        if(google){
            result = await API.socialLoginGoogle(user); 
        }else if(facebook){
            result = await API.socialLoginFacebook(user); 
        }else{
            result = await API.login(user); 
        }
        
        if(result.data.error === false){
            dispatch({
                type: LOGIN_SUCCESS, 
                payload:result.data.user        
            })
            dispatch(returnErrors('Login Successful', 200,'LOGIN_SUCCESS'));  
            const {redirectToReview} = getState().auth;
            if(redirectToReview){
                dispatch({
                    type: REDIRECT_TO_REVIEW         
                })
            }    
        }else{
            dispatch(returnErrors(result.data.message, 400,'LOGIN_FAILURE'));
            dispatch({
                type: LOGIN_FAILURE         
            })
        }  
        document.body.classList.remove('loading-indicator');     
    } catch (err) {    
        document.body.classList.remove('loading-indicator'); 
        dispatch(returnErrors('Server Error Occured', 400,'LOGIN_FAILURE'));
        dispatch({
            type: LOGIN_FAILURE          
        })
    }
}

export const getStatistics = () => async dispatch => {    
    try { 
        document.body.classList.add('loading-indicator');
        const result = await API.getStatistics(); 
        if(result.data.error === false){
            dispatch({
                type: GET_STATISTICS_SUCCESS, 
                payload:result.data        
            })
        }else{
            dispatch(returnErrors(result.data.message, 400,'GET_STATISTICS_SUCCESS'));
            dispatch({
                type: GET_STATISTICS_FAILURE        
            })
        }  
        document.body.classList.remove('loading-indicator');     
    } catch (err) {    
        document.body.classList.remove('loading-indicator'); 
        dispatch(returnErrors('Server Error Occured', 400,'GET_STATISTICS_FAILURE'));
        dispatch({
            type: GET_STATISTICS_FAILURE          
        })
    }
}

export const submitUserScore = (remark, score) => async (dispatch, getState) => {    
    try {
        document.body.classList.add('loading-indicator');        
        const { submittedAnswers, subjectId, speed, correctAnswers, answers, questionLength, userId} = getState().auth;
        const response = {
            "results":submittedAnswers,
            "subject_id":subjectId,
            "time_spent":speed,
            "no_correct_questions":correctAnswers,
            "no_wrong_questions":questionLength - (correctAnswers + answers.filter(item => item === -1).length),
            "no_skipped_questions":answers.filter(item => item === -1).length,
            "remark": remark,
            "score": score,
            "user_id": userId
        }     
        const result = await API.submitScore(response);  
        if(result.data.error === false){
            dispatch({
                type: SUBMIT_RESULT_SUCCESS                     
            })  
        }else{        
            dispatch({
                type: SUBMIT_RESULT_FAILURE        
            })
        }  
        
        document.body.classList.remove('loading-indicator');       
        
    } catch (err) {   
        document.body.classList.remove('loading-indicator');       
        dispatch({
            type: SUBMIT_RESULT_FAILURE           
        })	
    }
}

export const resetPassword = email => async dispatch => {    
    try { 
        document.body.classList.add('loading-indicator');
        const result = await API.resetPassword(email); 
        if(result.data.error === false){
            dispatch({
                type: RESET_PASSWORD_SUCCESS
            })
            dispatch(returnErrors(result.data.message, 200,'RESET_PASSWORD_SUCCESS')); 
        }else{
            dispatch(returnErrors(result.data.message, 400,'RESET_PASSWORD_FAILURE'));
            dispatch({
                type: RESET_PASSWORD_FAILURE        
            })
        }  
        document.body.classList.remove('loading-indicator');     
    } catch (err) {    
        document.body.classList.remove('loading-indicator'); 
        dispatch(returnErrors('Server Error Occured', 400,'RESET_PASSWORD_FAILURE'));
        dispatch({
            type: RESET_PASSWORD_FAILURE           
        })
    }
}

export const changePassword = data => async dispatch => {    
    try { 
          
       if(!data.token || !data.email){
            dispatch(returnErrors('Error verifying User', 400,'CHANGE_PASSWORD_FAILURE'));
            dispatch({
                type: CHANGE_PASSWORD_FAILURE         
            })
       }else{
            document.body.classList.add('loading-indicator');
            const verificationData = {
                email:data.email,
                token:data.token
            }

            const verificationResult = await API.verifyToken(verificationData);  
                  
            if(verificationResult.data.error === false){
                if(data.password !== data.confirmPassword){
                    dispatch(returnErrors('Confirm password does not match', 400,'CHANGE_PASSWORD_FAILURE'));
                    dispatch({
                        type: CHANGE_PASSWORD_FAILURE         
                    })
                    document.body.classList.remove('loading-indicator'); 
                }else{                    
                    const user = {
                        email: data.email,
                        password: data.password
                    }
                    
                    const result = await API.changePassword(user);                
                    
                    if(result.data.error === false){
                        dispatch({
                            type: CHANGE_PASSWORD_SUCCESS                        
                        })                  
                        dispatch(returnErrors(result.data.message, 200,'CHANGE_PASSWORD_SUCCESS'));               
                    }else{                        
                        dispatch(returnErrors(result.data.message, 400,'CHANGE_PASSWORD_FAILURE'));
                      
                    }  
                    document.body.classList.remove('loading-indicator');       
                 } 
                          
            }else{
                dispatch(returnErrors(verificationResult.data.message, 400,'CHANGE_PASSWORD_FAILURE'));
                document.body.classList.remove('loading-indicator');   
            } 
       } 
   
    } catch (err) {       
        document.body.classList.remove('loading-indicator'); 
        dispatch(returnErrors('Server Error Occured', 400,'CHANGE_PASSWORD_FAILURE'));
       
    }
}

export const search = data => async dispatch => {    
    try {  
        dispatch({
            type: ISLOADING           
        })     
        let keyword = data.keyword.replace(/ /gi,":");
        console.log(keyword);
        const result = await API.search(keyword);         
        if(result.data.error === false){
            if(result.data.subjects){
                dispatch({
                    type: SEARCH_SUCCESS,
                    payload:result.data.subjects
                })
            }else{
                dispatch({
                    type: SEARCH_SUCCESS,
                    payload:[]
                })
            }
            dispatch({
                type: LOADING_COMPLETE           
            })            
        }else{          
            dispatch({
                type: SEARCH_FAILURE       
            })
        }           
    } catch (err) {   
        dispatch(returnErrors('Server Error Occured', 400,'SEARCH_FAILURE'));
        dispatch({
            type: SEARCH_FAILURE          
        })
    }
}

export const getHistory = () => async dispatch => {    
    try { 
        document.body.classList.add('loading-indicator');
        const result = await API.getHistory(localStorage.getItem('userId')); 

        if(result.data.error === false){
            dispatch({
                type: LOAD_RESULT_HISTORIES_SUCCESS, 
                payload:result.data.results        
            })           
        }else{         
            dispatch({
                type: LOAD_RESULT_HISTORIES_FAILURE       
            })
        }  
        document.body.classList.remove('loading-indicator');     
    } catch (err) {    
        document.body.classList.remove('loading-indicator'); 
        dispatch(returnErrors('Server Error Occured', 400,'LOAD_RESULT_HISTORIES_FAILURE'));
        dispatch({
            type: LOAD_RESULT_HISTORIES_FAILURE          
        })
    }
}
export const getResult = data => async dispatch => {    
    try { 
        document.body.classList.add('loading-indicator');
        const result = await API.getResult(data); 

        if(result.data.error === false){
            dispatch({
                type: GET_RESULT_SUCCESS, 
                payload:result.data.result        
            })           
        }else{         
            dispatch({
                type: GET_RESULT_FAILURE       
            })
        }  
        document.body.classList.remove('loading-indicator');     
    } catch (err) {    
        document.body.classList.remove('loading-indicator'); 
        dispatch(returnErrors('Server Error Occured', 400,'GET_RESULT_FAILURE'));
        dispatch({
            type: GET_RESULT_FAILURE           
        })
    }
}