import React from "react";
import { connect } from 'react-redux';
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { loadSubjects, inputChange } from './../../redux/actions/authActions';

const CategoryBox = props => { 
    const handleGotoSubjects = (e) =>{ 
        e.preventDefault()
        props.inputChange('selectedCategoryImg', props.image);
        props.inputChange('selectedCategory', props.title);
        props.loadSubjects(props.categoryId, props.examId);
    }    
	return (        
        <div className="col-md-3">
            <Link to='/category' onClick={ handleGotoSubjects }> 
                <span>
                    <div className="col-12">
                        <img src={props.image} alt={props.title} className="examImage"/> 
                    </div>
                    <div className="col-12 title">
                        {props.title}
                    </div>
                </span>
            </Link>
        </div>        
	);
};

CategoryBox.propTypes= {   
    loadSubjects: PropTypes.func.isRequired,
    inputChange: PropTypes.func.isRequired        
}

export default connect(null, {loadSubjects, inputChange})(CategoryBox);