import React, { useEffect } from "react";
import { Provider } from "react-redux";
import store from "./../src/redux/store";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.min.js";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './App.css';
import Navigation from './components/includes/nav.component';
import { loadUser, getStatistics, loadCategories} from './redux/actions/authActions';
import ReactGA from "react-ga";


const App = () => {
  useEffect(() => {
    store.dispatch(loadUser());
    store.dispatch(getStatistics());
    store.dispatch(loadCategories())
    ReactGA.initialize('G-E9FQ5ZHM2G');
    ReactGA.pageview(window.location.pathname + window.location.search);  
  });

  return (
    <Provider store={store}>
      <Navigation/>
    </Provider>
  );
};
export default App;