import React from "react";
import Slider from "react-slick";
import Box from "./examCategoryBox.component";

class SimpleSlider extends React.Component { 
  render() {
    var settings = {
      dots: true,
      autoplay:true,
      infinite: true,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      mobileFirst: true,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }       
      ]
    };
    return (
      <Slider {...settings}>       
            <Box imageUrl={require('../../assets/img/waec.png')}              
                title='West African Examination Council'
                description='All the WAEC questions from year 2000 to 2019. May/June, October/November included.'
                examId='1'
                categoryId='2'
            />    
            <Box imageUrl={require('../../assets/img/jamb.png')}              
                title='Joint Admission & Matriculation Board'
                description='Prepare for your JAMB exam like a pro. Simulate the JAMB exam and beat the highest scores and records.'
                examId='2'
                categoryId='2'
           /> 
            <Box imageUrl={require('../../assets/img/neco.png')}             
                title='National Examinations Council'
                description="We've got it all - NCEE, SSCE, BECE - curated to ensure you have the best assessment experience."
                examId='5'
                categoryId='2'
            /> 
            <Box imageUrl={require('../../assets/img/common-entrance.jpg')}                
                title='Common Entrance'
                description="Master the secondary school entrance examination like a champ. Practice with ease."
                examId='7'
                categoryId='1'
            />  
            <Box imageUrl={require('../../assets/img/waec.png')}               
                title='BECE'
                description="Attempt all your Junior WAEC exams on our platform like a game. Practice till you become a pro."
                examId='8'
                categoryId='5'
            />    
            <Box imageUrl={require('../../assets/img/Post-utme.png')}                
                title='Post UTME'
                description="Get a chance to be selected by your favourite institution by preparing for their examination here."
                examId='3'
                categoryId='3'
           />                   
      </Slider>
    );
  }
}
export default SimpleSlider;