export const INPUT_CHANGE = 'INPUT_CHANGE';
export const LOAD_SUBJECTS_SUCCESS = 'LOAD_SUBJECTS_SUCCESS';
export const LOAD_SUBJECTS_FAILURE = 'LOAD_SUBJECTS_FAILURE';
export const CHANGE_DISPLAY_SUBJECTS = 'CHANGE_DISPLAY_SUBJECTS';
export const LOAD_QUESTIONS_SUCCESS = 'LOAD_QUESTIONS_SUCCESS';
export const LOAD_QUESTIONS_FAILURE = 'LOAD_QUESTIONS_FAILURE';
export const GET_ERRORS = 'GET_ERRORS';
export const CLEAR_ERRORS = 'CLEAR_ERRORS';
export const SAVE_USER_ANSWER = 'SAVE_USER_ANSWER';
export const UPDATE_QUESTION_TAG = 'UPDATE_QUESTION_TAG';
export const FILTER_SUBJECTS = 'FILTER_SUBJECTS';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAILURE = 'REGISTER_FAILURE';
export const CLEAR_FORM = 'CLEAR_FORM';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAILURE = 'AUTH_FAILURE';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const REDIRECT_TO_REVIEW = 'REDIRECT_TO_REVIEW';
export const GET_STATISTICS_SUCCESS = 'GET_STATISTICS_SUCCESS';
export const GET_STATISTICS_FAILURE= 'GET_STATISTICS_FAILURE';
export const POPULATE_SUBMITTED_ANSWER = 'POPULATE_SUBMITTED_ANSWER';
export const SUBMIT_RESULT_SUCCESS = 'SUBMIT_RESULT_SUCCESS';
export const SUBMIT_RESULT_FAILURE= 'SUBMIT_RESULT_FAILURE';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAILURE = 'RESET_PASSWORD_FAILURE';
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_FAILURE= 'CHANGE_PASSWORD_FAILURE';
export const SEARCH_SUCCESS = 'SEARCH_SUCCESS';
export const SEARCH_FAILURE = 'SEARCH_FAILURE';
export const ISLOADING = 'ISLOADING';
export const LOADING_COMPLETE = 'LOADING_COMPLETE';
export const LOAD_RESULT_HISTORIES_SUCCESS = 'LOAD_RESULT_HISTORIES_SUCCESS';
export const LOAD_RESULT_HISTORIES_FAILURE = 'LOAD_RESULT_HISTORIES_FAILURE';
export const LOAD_CATEGORIES_FAILURE = 'LOAD_CATEGORIES_FAILURE';
export const LOAD_CATEGORIES_SUCCESS = 'LOAD_CATEGORIES_SUCCESS';
export const FLAG_QUESTION_SUCCESS = 'FLAG_QUESTION_SUCCESS';
export const FLAG_QUESTION_FAILURE = 'FLAG_QUESTION_FAILURE';
export const GET_RESULT_SUCCESS = 'GET_RESULT_SUCCESS';
export const GET_RESULT_FAILURE = 'GET_RESULT_FAILURE';
