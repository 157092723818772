import React, { useEffect, useRef } from "react";
import './css/style.css';
import Header from './../../includes/header/header.component';
import Sidebar from './../../includes/sidebar.component';

const LandingPage = props => {  
    const mounted = useRef(); 
    useEffect(()=>{
        if (!mounted.current) {
            // do componentDidMount logic
            mounted.current = true;
            window.scrollTo(0, 0);            
        } else {
            // do componentDidUpdate logic          
          } 	       
    })       
   
	return (        
		<>          
           <Header/>          
           <div className="container-fluid Category">
                <div className="row">
                    <Sidebar/>
                    <div className="col-md-10 afterNav">
                        <h3 className="center">Oops! Still Under Construction</h3>
                     </div>   
                 </div>
            </div>
        </>
	);
};

export default LandingPage;