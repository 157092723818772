import React from "react";
import { Link } from "react-router-dom";
import { connect } from 'react-redux';

const SideBar = props => {
    const {        
        isAuthenticated
    } = props;
	return (
        <div className="col-md-2 side desktopOnly">
            <ul>
                <li><Link to="/">Home</Link></li>
                <li><Link to="/category">Exam Categories</Link></li>
                { isAuthenticated ? <li><Link to="/histories">Histories & Scores</Link></li> : null}
            </ul>
        </div>
	);
};
const mapStateToProps = state => ({   
    isAuthenticated: state.auth.isAuthenticated,
})
export default connect(mapStateToProps, null)(SideBar);