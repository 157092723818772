import React, { useEffect, useRef } from "react";
import queryString from "query-string";
import { Alert } from 'reactstrap';
import { Link } from "react-router-dom";
import { connect } from 'react-redux';
import PropTypes from "prop-types";
import { inputChange, changePassword } from '../../../../redux/actions/authActions';

const ForgotPasssword = props => {  
    const mounted = useRef(); 
    useEffect(()=>{
        if (!mounted.current) {
            // do componentDidMount logic
            mounted.current = true;           
            props.inputChange('showMessage', false);                         
        } else {           
            if(error.id ==='CHANGE_PASSWORD_SUCCESS'){                
                props.inputChange('showMessageClass', 'success');               
            }else{
                props.inputChange('showMessageClass', 'danger'); 
            }
           
            if(error.id ==='CHANGE_PASSWORD_SUCCESS' || error.id ==='CHANGE_PASSWORD_FAILURE'){
                props.inputChange('showMessage', true);
            }  
          } 	       
    })  
      
    const handleChange = (e) => {      	
        const target = e.target;
		const name = target.name;
		const value = target.type === 'checkbox' ? target.checked : target.value;
		props.inputChange(name, value);	
    }
    
    const handlePrivacySection = (category, e) =>{               
        props.inputChange('privacyStatus', category);
    }

    const handleShowLogin= () =>{                   
        props.inputChange('showLogin', true);
    }
      
    const {
        password,
        confirmPassword,
        showMessage,
        showMessageClass,    
        error        
    } = props;  
    
    const handleChangePassword = (e) => {		
        e.preventDefault();
        let token = null;
        let email = null;
        const parsed = queryString.parse(props.location.search);

        if(Object.keys(parsed).length){
            token = parsed.token;
            email = parsed.uid; 
        }
            		
        const data ={
            token,
            email,
            password,
            confirmPassword                 
        }	
        props.changePassword(data)       
	} 
 
    
	return (        
		<>            
           <div className="container-fluid forgotPassword changePasswordPush">
                <div className="row">
                    <div className="col-md-4">

                    </div>
                    <div className="col-md-4">
                        <div className="row">
                            <div className="col-12">
                                <Link to="/"><img src={require('../../../../assets/img/logo.png')} alt='logo' className="logo"/></Link>
                                <p className="p1">Change Password</p>
                                <p>{showMessage ? <Alert color={showMessageClass}>{ error.msg.isArray ? error.msg.map(item => {return <>{item.msg} <br/></> }): error.msg }<br/><Link onClick={handleShowLogin} to="/">Login</Link></Alert> : null}</p>
                                <p className="p2 center">Enter your new password to proceed</p>
                            </div>
                        </div> 
                        <form onSubmit={handleChangePassword}>                         
                            <div className="row">                          
                                <div className="col-12">
                                    <input type="password" placeholder="New Password" name="password" value={password} onChange={handleChange} required/>
                                </div>                                                  
                            </div>    
                            <div className="row">                          
                                <div className="col-12">
                                    <input type="password" placeholder="Confirm New Password" name="confirmPassword" value={confirmPassword} onChange={handleChange} required/>
                                </div>                                                  
                            </div>    
                            <div className="row relative">                          
                                <div className="col-12">
                                    <input type="submit" value="Submit"/>                               
                                </div>                                                  
                            </div>     
                        </form>                   
                        <div className="row push">                          
                            <div className="col-12 center">
                               <p>By signing in to Exambly, you agree to our <br className="desktopOnly"/> <Link className="bold" onClick={handlePrivacySection.bind(this, 1)} to="/privacy_policy">Terms</Link> and <Link className="bold" onClick={handlePrivacySection.bind(this, 2)} to="/privacy_policy">Privacy Policy</Link>.</p>
                            </div>                                                                         
                        </div>                      
                    </div>
                    <div className="col-md-4">

                    </div>              
                </div>
           </div>     
        </>
	);
};

ForgotPasssword.propTypes= {   
    inputChange: PropTypes.func.isRequired,  
}

const mapStateToProps = state => ({ 
    password: state.auth.password,
    confirmPassword: state.auth.confirmPassword,
    showMessage: state.auth.showMessage,
    showMessageClass: state.auth.showMessageClass,
    error:  state.error,
})
export default connect(mapStateToProps, {inputChange, changePassword})(ForgotPasssword);