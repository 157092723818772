import React, { useEffect, useRef } from "react";
import './css/style.css';
import { Link } from "react-router-dom";
import Header from './../../includes/header/header.component';
import Footer from './../../includes/footer/footer.component';
import { connect } from 'react-redux';
import { inputChange } from './../../../redux/actions/authActions';
import PropTypes from "prop-types";

const Privacy = props => {  
    const mounted = useRef();   
    useEffect(()=>{
        if (!mounted.current) {
            // do componentDidMount logic
            mounted.current = true;
            window.scrollTo(0, 0); 
            props.inputChange('privacyPage', true);                   
        } else {
            // do componentDidUpdate logic          
          } 	       
    }) 
    const { privacyStatus } = props;
      
	return (        
		<>         
           <Header/>
               <div className="container terms">
                    <div className="row">
                        <div className="col-md-2">    </div>
                        <div className={`col-md-1 ${privacyStatus ===1? 'on' : 'off'}`}>
                            <img src={require('../../../assets/img/terms.svg')} alt="banner" className=""/>              
                        </div>
                        <div className={`col-md-1 ${privacyStatus ===2? 'on' : 'off'}`}>
                            <img src={require('../../../assets/img/privacy.svg')} alt="banner" className=""/>              
                        </div>
                        <div className={`col-md-7 paddingLeftOff ${privacyStatus ===1? 'on' : 'off'}`}>
                          <p className="p1">Terms of Service</p>
                          <p className="p2">
                          We, at Exambly are sharing with you how we collect, use and share your personal data because we care deeply about you and your data.<br/><br/>
 
                            This Privacy Policy (“Privacy Policy”) details the personal data Exambly, (“Exambly”, “we”, “us” or “our”) receives about you, how we process it and your rights and obligations in relation to your personal data. Exambly, a product of Afrilearn International Limited registered in 2019 with Corporate Affairs Commission, Nigeria, is the data controller for the purposes of the General Data Protection Regulation (“GDPR”) and any relevant local legislation (“Data Protection Laws”).<br/><br/>
                            
                            By using or accessing the Service, you agree to the terms of this Privacy Policy. Capitalized terms not defined here have the meanings set forth in the terms and conditions (the “Terms and Conditions”), located at www.exambly.com/terms. We may update our Privacy Policy to reflect changes to our information practices. If we do this and the changes are material, we will post a notice that we have made changes to this Privacy Policy on the Website for at least 7 days before the changes are made, and we will indicate the date these terms were last revised at the bottom of the Privacy Policy. Any revisions to this Privacy Policy will become effective at the end of that 7 day period.<br/><br/>
                            
                            If you are an employee, worker or contractor of Exambly, the information about how we handle your personal information is available in the Exambly internal knowledge base. With respect to employees based in Europe, we are committed to cooperating with EU data protection authorities (DPAs) and comply with the advice given by such authorities with regard to human resources data transferred from the EU in the context of the employment relationship.<br/><br/>
                            
                            Information We Collect<br/>
                            
                            This Privacy Policy explains how we collect, use and share your personal data.<br/><br/>
                            
                            
                            
                            Information You Provide<br/>
                            
                            Through the registration process, you will provide us with your name, e-mail address (or parent’s e-mail address), and age or date of birth. You will also provide us with your payment transaction information if you choose to pay for Exambly services.<br/><br/>
                            
                            Activity Data<br/>
                            
                            When you use the Service, you will submit information and content to your profile. We will also generate data about your use of our Services including your engagement in educational activities on the Service, or your sending of messages and otherwise transmitting information to other users (“Activity Data”). We also collect technical data about how you interact with our Services; for more information, see Cookies.<br/><br/>
                            
                            When you use Exambly in our app or on our website, we use a service named FullStory to log your activity. This provides us with a set of data and a session replay of your activity on Exambly in the form of a video. FullSory captures and analyzes your usage data to help us make your Exambly experience better.

                          </p>
                        </div>
                        <div className={`col-md-7 paddingLeftOff ${privacyStatus ===2? 'on' : 'off'}`}>
                          <p className="p1">Privacy Policy</p>
                          <p className="p2">
                          We, at Exambly are sharing with you how we collect, use and share your personal data because we care deeply about you and your data.<br/><br/>
 
                            This Privacy Policy (“Privacy Policy”) details the personal data Exambly, (“Exambly”, “we”, “us” or “our”) receives about you, how we process it and your rights and obligations in relation to your personal data. Exambly, a product of Afrilearn International Limited registered in 2019 with Corporate Affairs Commission, Nigeria, is the data controller for the purposes of the General Data Protection Regulation (“GDPR”) and any relevant local legislation (“Data Protection Laws”).<br/><br/>
                            
                            By using or accessing the Service, you agree to the terms of this Privacy Policy. Capitalized terms not defined here have the meanings set forth in the terms and conditions (the “Terms and Conditions”), located at www.exambly.com/terms. We may update our Privacy Policy to reflect changes to our information practices. If we do this and the changes are material, we will post a notice that we have made changes to this Privacy Policy on the Website for at least 7 days before the changes are made, and we will indicate the date these terms were last revised at the bottom of the Privacy Policy. Any revisions to this Privacy Policy will become effective at the end of that 7 day period.<br/><br/>
                            
                            If you are an employee, worker or contractor of Exambly, the information about how we handle your personal information is available in the Exambly internal knowledge base. With respect to employees based in Europe, we are committed to cooperating with EU data protection authorities (DPAs) and comply with the advice given by such authorities with regard to human resources data transferred from the EU in the context of the employment relationship.<br/><br/>
                            
                            Information We Collect<br/>
                            
                            This Privacy Policy explains how we collect, use and share your personal data.<br/><br/>
                            
                            
                            
                            Information You Provide<br/>
                            
                            Through the registration process, you will provide us with your name, e-mail address (or parent’s e-mail address), and age or date of birth. You will also provide us with your payment transaction information if you choose to pay for Exambly services.<br/><br/>
                            
                            Activity Data<br/>
                            
                            When you use the Service, you will submit information and content to your profile. We will also generate data about your use of our Services including your engagement in educational activities on the Service, or your sending of messages and otherwise transmitting information to other users (“Activity Data”). We also collect technical data about how you interact with our Services; for more information, see Cookies.<br/><br/>
                            
                            When you use Exambly in our app or on our website, we use a service named FullStory to log your activity. This provides us with a set of data and a session replay of your activity on Exambly in the form of a video. FullSory captures and analyzes your usage data to help us make your Exambly experience better.

                          </p>
                        </div>
                      
                        <div className="col-md-2">    </div>
                    </div>                    
               </div>             
               <div className="container-fluid landingPagePartThree relative">     
                    <img src={require('../../../assets/img/curve3.png')} alt="banner" className="curve5 desktopOnly"/>            
                    <div className="row">
                        <div className="col-md-12">
                            Ready, set &nbsp;&nbsp;&nbsp; <Link className="bigButton" to="/category"> <img src={require('../../../assets/img/play.svg')} alt='log2o' className="logo"/>Take your First Test</Link>
                        </div>                   
                    </div>  
                    <img src={require('../../../assets/img/curve3.png')} alt="banner" className="curve2 desktopOnly"/>             
                </div> 
           <Footer/> 
        </>
	);
};

Privacy.propTypes= {   
    inputChange: PropTypes.func.isRequired,  
}

const mapStateToProps = state => ({ 
    privacyStatus: state.auth.privacyStatus  
})

export default connect(mapStateToProps, {inputChange})(Privacy);