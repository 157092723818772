import { 
    INPUT_CHANGE,
    LOAD_SUBJECTS_SUCCESS,
    CHANGE_DISPLAY_SUBJECTS,
    LOAD_QUESTIONS_SUCCESS,
    SAVE_USER_ANSWER,
    UPDATE_QUESTION_TAG,
    FILTER_SUBJECTS,
    REGISTER_SUCCESS,    
    REGISTER_FAILURE,
    CLEAR_FORM,
    LOGIN_FAILURE,    
    LOGIN_SUCCESS, 
    AUTH_FAILURE,
    AUTH_SUCCESS,
    REDIRECT_TO_REVIEW,
    GET_STATISTICS_SUCCESS,
    POPULATE_SUBMITTED_ANSWER,
    SEARCH_SUCCESS,
    ISLOADING,
    LOADING_COMPLETE,
    LOAD_RESULT_HISTORIES_SUCCESS,
    LOAD_CATEGORIES_SUCCESS,
    FLAG_QUESTION_SUCCESS,
    GET_RESULT_SUCCESS,
    GET_RESULT_FAILURE
  
   } from '../actions/types';

   const initialState  = { 
    seeMore:false,
    footerMoreOptions:false,
    WAEC:[],
    NECO:[],
    JAMB:[],   
    displayedSubjects:[],
    filterCategory:0, 
    redirect: false,   
    location:'/exam',
    questions:[],
    answers:[],
    submittedAnswers:[],
    correctAnswers:0,
    skippedAnswers:0,
    selectedCategory:'Unknown',
    selectedSubject:'Unknown',
    selectedYear:'Unknown',
    selectedSubjectImg:'',
    selectedCategoryImg:'',
    currentQuestion:0,
    progressBarStatus:0,  
    progressBarUnit:0,
    questionLength:0,
    questionTags:[],
    questionTime:3600000,
    email:'',
    password:'', 
    confirmPassword:'',   
    firstname:'',
    lastname:'',
    mobile:'',      
    token:localStorage.getItem('token'),
    isAuthenticated:false,    
    user:{},    
    passcord:'',
    signUpMessage:false,
    loginMessage:false,      
    showMessage: false,
    showMessageClass: 'danger',
    firstName:'',
    lastName:'',
    logout:false,
    pendingResult: false,
    redirectToReview:false,
    averagePassRate:0,
    completedTest:0,
    noOfStudents:0,
    subjectId:null,
    userId:null,
    keyword:'',
    isLoading: false,
    searchResults:[],
    showResult:false,
    privacyStatus:2,
    privacyPage:false,
    showLogin:false,
    showSignup:false,    
    speed:'Fast',
    speedRange1: 0,
    speedRange2: 0,
    speedRange3: 0,
    histories:[],
    categories:[],
    subjectTag:'School',
    motivations:[],
    motivationItemNo:0,
    motivationInterval: 0,
    motivateGoodPerformance: false,
    report1:true,
    report2:false,
    report3:false,
    report4:false,
    report5:false,
    report6:false,
    report7:'',  
    certificateName:'' ,
    result:{}
};

const authReducer = (state= initialState, action) =>{
    switch(action.type){        
        case INPUT_CHANGE:
            return{
                ...state,
                [action.payload.name]:action.payload.value                              
            }  

        case LOAD_CATEGORIES_SUCCESS:
            return{
                ...state, 
                categories:action.payload                                                             
            }  
        case GET_RESULT_SUCCESS:
            return{
                ...state, 
                result:action.payload                                                             
            }      
        case LOAD_SUBJECTS_SUCCESS:
            return{
                ...state, 
                subjects:action.payload,
                location:'/subject',
                redirect:true                                                               
            }    

        case FLAG_QUESTION_SUCCESS:
            return{
                ...state, 
                report1:true,
                report2:false,
                report3:false,
                report4:false,
                report5:false,
                report6:false,
                report7:'',                                                           
            } 

        case CHANGE_DISPLAY_SUBJECTS:
            let mySubjects = {};             
            if(action.payload === 2){
                mySubjects ={
                    displayedSubjects:state.JAMB      
                }
            }else if(action.payload === 5){
                mySubjects ={
                    displayedSubjects:state.NECO      
                }
            }else{
                mySubjects ={
                    displayedSubjects:state.WAEC     
                }
            } 

            return{
                ...state,
                ...mySubjects                            
            }
        
        case FILTER_SUBJECTS:
                let myNewSubjects = {};  
                
                if(action.payload === 1){   

                    if(state.category === 2){
                        myNewSubjects ={
                            displayedSubjects:state.JAMB.filter(el=> el.classification == 'arts')       
                        }
                    }else if(state.category === 3){
                        myNewSubjects ={
                            displayedSubjects:state.NECO.filter(el=> el.classification == 'arts')       
                        }
                    }else{
                        myNewSubjects ={
                            displayedSubjects:state.WAEC.filter(el=> el.classification == 'arts')       
                        }
                    }             
                   
                }else if(action.payload === 2){
                    if(state.category === 2){
                        myNewSubjects ={
                            displayedSubjects:state.JAMB.filter(el=> el.classification == 'commercials')       
                        }
                    }else if(state.category === 3){
                        myNewSubjects ={
                            displayedSubjects:state.NECO.filter(el=> el.classification == 'commercials')       
                        }
                    }else{
                        myNewSubjects ={
                            displayedSubjects:state.WAEC.filter(el=> el.classification == 'commercials')       
                        }
                    }        
                   
                }else if(action.payload === 3){
                    if(state.category === 2){
                        myNewSubjects ={
                            displayedSubjects:state.JAMB.filter(el=> el.classification == 'sciences')       
                        }
                    }else if(state.category === 3){
                        myNewSubjects ={
                            displayedSubjects:state.NECO.filter(el=> el.classification == 'sciences')       
                        }
                    }else{
                        myNewSubjects ={
                            displayedSubjects:state.WAEC.filter(el=> el.classification == 'sciences')       
                        }
                    }  
                  
                }else if(action.payload === 4){
                    if(state.category === 2){
                        myNewSubjects ={
                            displayedSubjects:state.JAMB.filter(el=> el.classification == 'languages')       
                        }
                    }else if(state.category === 3){
                        myNewSubjects ={
                            displayedSubjects:state.NECO.filter(el=> el.classification == 'languages')       
                        }
                    }else{
                        myNewSubjects ={
                            displayedSubjects:state.WAEC.filter(el=> el.classification == 'languages')       
                        }
                    }  
                  
                }else{
                    if(state.category === 2){
                        myNewSubjects ={
                            displayedSubjects:state.JAMB      
                        }
                    }else if(state.category === 3){
                        myNewSubjects ={
                            displayedSubjects:state.NECO      
                        }
                    }else{
                        myNewSubjects ={
                            displayedSubjects:state.WAEC       
                        }
                    } 
                } 
    
                return{
                    ...state,
                    ...myNewSubjects                            
            }

        case LOAD_QUESTIONS_SUCCESS:                           
            return{
                ...state, 
                questions:action.payload.questions, 
                progressBarUnit:100/action.payload.questions.length,  
                progressBarStatus:100/action.payload.questions.length,  
                location:'/instructions',
                redirect:true,
                questionLength:action.payload.questions.length,
                questionTags:action.payload.questionTags,
                questionTime: action.payload.questionTime,
                answers:[],
                correctAnswers:0,
                subjectId:action.payload.theSubjectId ,
                speedRange1: action.payload.questionTime/4,
                speedRange2: (action.payload.questionTime/4) * 2,  
                speedRange3: (action.payload.questionTime/4) * 3,
                motivations: action.payload.motivations,                                                    
            } 

        case SAVE_USER_ANSWER:           
            state.answers[state.currentQuestion] = action.payload.value
            return{
                ...state                                              
            }
        
        case POPULATE_SUBMITTED_ANSWER:           
            state.submittedAnswers[state.currentQuestion] = action.payload.value
            return{
                ...state                                              
            }
        
        case REDIRECT_TO_REVIEW:   
            return{
                ...state ,
                pendingResult:true                                             
            }
        
        case UPDATE_QUESTION_TAG:           
            state.questionTags[state.currentQuestion] = action.payload.value                           
            return{
                ...state                                              
            }
        
        case GET_STATISTICS_SUCCESS: 
            return{
                ...state,
                averagePassRate: action.payload.avg_pass_rate,
                completedTest: action.payload.completed_tests,
                noOfStudents: action.payload.no_of_students,
            }
        
        case SEARCH_SUCCESS: 
            return{
                ...state,
                searchResults: action.payload              
            }
        
        case LOAD_RESULT_HISTORIES_SUCCESS: 
            return{
                ...state,
                histories: action.payload              
            }
        
        case ISLOADING: 
            return{
                ...state,
                isLoading: true,               
                showResult:false           
            }
        
        case LOADING_COMPLETE: 
            return{
                ...state,
                isLoading: false,               
                showResult:true           
            }

        case REGISTER_SUCCESS:  
        case AUTH_SUCCESS:  
        case LOGIN_SUCCESS: 
            localStorage.setItem('token', action.payload.auth_token);  
            localStorage.setItem('firstName', action.payload.firstname);    
            localStorage.setItem('lastName', action.payload.lastname);   
            localStorage.setItem('userId', action.payload.user_id);  
                                      
            return{
                ...state, 
               user: action.payload, 
               token: action.payload.auth_token,
               isAuthenticated:true,   
               firstName: action.payload.firstname,  
               lastName:  action.payload.lastname,
               userId:  action.payload.user_id                                      
            }

        case CLEAR_FORM :
            return{
                ...state,               
                email:'',
                password:'',
                firstname:'',
                lastname:'',
                mobile:''                                                      
            } 

        case LOGIN_FAILURE:
        case REGISTER_FAILURE:
        case AUTH_FAILURE:      
            localStorage.removeItem('token');
            return{
                ...state,
                token:null,               
                isAuthenticated:false,
                user:{}                                                      
            } 
               
        default:
            return state;
        
    }
}
export default authReducer;