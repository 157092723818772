import React, { useEffect, useRef } from "react";
import './css/style.css';
import { Link, Redirect } from "react-router-dom";
import { connect } from 'react-redux';
import PropTypes from "prop-types";
import { inputChange } from '../../../redux/actions/authActions';
import Header from '../../includes/header/header.component';
import Sidebar from '../../includes/sidebar.component';
import Box from '../../includes/categoryBox.component';

const CategoryPage = props => {  
    const mounted = useRef(); 
    useEffect(()=>{
        if (!mounted.current) {
            // do componentDidMount logic
            mounted.current = true;
            window.scrollTo(0, 0);
            props.inputChange('redirect', false);	 
        } else {
            // do componentDidUpdate logic          
          } 	       
    })  
   
    const {        
        categories,
        redirect,
        location, 
        showResult  
    } = props;

    const handleCloseBox = e =>{   
        e.preventDefault();
        if(showResult){
            props.inputChange('showResult', false);	 
        }         
    }
  
    const categoryList = () => {         
        if(categories && categories.length){
            return categories.map((item, index) => {            
                return <Box index={index} key={item.exam_id} title={item.exam_name} image={item.exam_image} categoryId={item.category_id} examId={item.exam_id}/>               
            })
        }else{
           return <h5>0ops!, No category found</h5>
        }
		
    }
    
	return (        
		<>   
           {redirect ? <Redirect to={location} /> : null}       
           <Header/>
           <div className="container-fluid Category" onClick={handleCloseBox}>
                <div className="row">
                   <Sidebar/>
                   <div className="col-md-10 afterNav">
                        <div className="row kk">
                           <div className="col-md-12">
                                <h4 className="headingOne">Choose Category</h4>
                           </div>                          
                        </div>
                        <div className="row sectionThree">
                           {categoryList()}
                        </div>
                   </div>
                </div>
           </div>     
        </>
	);
};

CategoryPage.propTypes= {   
    inputChange: PropTypes.func.isRequired,  
}

const mapStateToProps = state => ({   
    category: state.auth.category,
    filterCategory: state.auth.filterCategory,
    displayedSubjects: state.auth.displayedSubjects,
    redirect: state.auth.redirect,
    location: state.auth.location, 
    showResult: state.auth.showResult,
    categories: state.auth.categories
})
export default connect(mapStateToProps, {inputChange})(CategoryPage);