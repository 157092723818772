import React, { useEffect, useRef } from "react";
import './css/style.css';
import { Link, Redirect } from "react-router-dom";
import Header from './../../includes/header/header.component';
import Footer from './../../includes/footer/footer.component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGamepad, faFighterJet, faGraduationCap, faStar} from '@fortawesome/free-solid-svg-icons';
import Box from './../../includes/categorySlick.component';
import { connect } from 'react-redux';
import { inputChange } from './../../../redux/actions/authActions';
import PropTypes from "prop-types";

const LandingPage = props => {  
    const mounted = useRef();   
    useEffect(()=>{
        if (!mounted.current) {
            // do componentDidMount logic
            mounted.current = true;
            window.scrollTo(0, 0);  
            props.inputChange('pendingResult', false);   
            props.inputChange('redirect', false); 
            props.inputChange('location', '/exam');  
            props.inputChange('privacyPage', false);           
        } else {
            // do componentDidUpdate logic          
          } 	       
    })    
    const {
        pendingResult,
        redirect,
        location,
        showResult  
    }=props;   
   
    const handleCloseBox = e =>{   
        e.preventDefault();
        if(showResult){
            props.inputChange('showResult', false);	 
        }         
    }
	return (        
		<>  
           {pendingResult ? <Redirect to='/review'/> : null}  
           {redirect ? <Redirect to={location} /> : null}            
           <Header/>
           <div className="container landingPage" onClick={handleCloseBox}>
                <div className="row">
                    <div className="col-md-6">
                        <img src={require('../../../assets/img/Exambly Welcome page 33172-01-finishig-studies.gif')}  className="banner" alt="banner"/>
                    </div>
                    <div className="col-md-6 partTwo">
                        <p className="headingOne">Play. Pass. Progress.</p>
                        <p className="headingTwo">Prepare for local and international exams for free. Forever.</p>
                        <Link className="bigButton" to="/category"> <img src={require('../../../assets/img/play.svg')} alt='log2o' className="logo"/>Take your First Test</Link>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <p className="headingThree">All the exams you need to prepare for in one place</p>
                    </div>
                </div>
                <div className="row myProducts">                   
                    <Box/>
                </div>               
            </div>  
           <div className="container-fluid landingPageAdd relative">               
            <div className="row">
                <div className="col-md-12">
                    <p className="headingThree">The best way to pass any exam across Africa!</p>                      
                </div>
            </div>
            <div className="row row2">
                <div className="col-md-4">
                    <div className="row">
                        <div className="col-2">
                            <FontAwesomeIcon icon={faFighterJet} />
                        </div>
                        <div className="col-10 paddingLeftOff">
                            <h5>Enjoy personalised practice</h5> 
                            <p>Exambly’s unlimited exam practice adapts to your learning style with tailored tests that help you grasp and review exercises intuitively.</p>
                        </div>
                    </div>
                    <div className="row push">
                        <div className="col-2">
                            <FontAwesomeIcon icon={faGamepad} />
                        </div>
                        <div className="col-10 paddingLeftOff">
                            <h5>Gamified experience with rewards</h5> 
                            <p>Earn virtual wealth, unlock new levels, and watch your exam scores soar as you master new concepts and pass with flying colours.</p>
                        </div>
                    </div>
                </div>
                <div className="col-md-4">
                    <img src={require('../../../assets/img/animation_500_kgtd8u9z.gif')}  className="banner" alt="banner"/>
                </div>
                <div className="col-md-4">
                    <div className="row">
                        <div className="col-2">
                            <FontAwesomeIcon icon={faGraduationCap} />
                        </div>
                        <div className="col-10 paddingLeftOff">
                            <h5>Learn smart with instant results</h5> 
                            <p>You’ll quickly see which answers you get correct, and we'll instantly show you how to improve more effectively, should you miss an exercise.</p>
                        </div>
                    </div>
                    <div className="row push">
                        <div className="col-2">
                            <FontAwesomeIcon icon={faStar} />
                        </div>
                        <div className="col-10 paddingLeftOff">
                            <h5>Achieve unlimited success</h5> 
                            <p>Multiply your success with free exam practice anytime – no hidden fees, no premium content, all for you, forever.</p>
                        </div>
                    </div>
                </div>
            </div>               
        </div>   
           <div className="container-fluid landingPagePartTwo relative"> 
                <img src={require('../../../assets/img/curve1.png')} alt="banner" className="curve1 desktopOnly"/>              
                <div className="row">
                    <div className="col-md-12">
                        <p className="headingFour">Test anytime, anywhere, everywhere</p>
                        <p className="headingFive">A mobile device or laptop, and an internet connection is all you need</p>
                    </div>                   
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <img src={require('../../../assets/img/exambly-mockup-for-website.png')}  className="mobileSection" alt="screens"/>                   
                    </div> 
                    <div className="col-md-6">
                        <div className="row part7">
                            <div className="col-12">
                                <img src={require('../../../assets/img/under-construction.svg')} className="mobileSection1" alt="mobileSection"/>  
                                <p className="center p1">Looking for the app? We're still building it!</p> 
                                <p className="center">We'll let you know once it's ready for the big reveal.</p>                
                            </div>
                        </div>  
                        <div className="row mobileAppSection">
                            <div className="col-6">
                               <a href="https://play.google.com/store/apps/details?id=com.exambly" target="_blank"><img src={require('../../../assets/img/googleplay.png')} className="mobileApp centerImage" alt="mobileSection"/>  </a>
                            </div>
                            <div className="col-6">
                                <img src={require('../../../assets/img/apple.png')} className="mobileApp1 centerImage" alt="mobileSection"/>  
                            </div>
                        </div>                 
                    </div>                   
            </div>
        </div>
           <div className="container-fluid landingPagePartThree relative">     
                <img src={require('../../../assets/img/curve3.png')} alt="banner" className="curve5 desktopOnly"/>            
                <div className="row">
                    <div className="col-md-12">
                        Ready, set &nbsp;&nbsp;&nbsp; <Link className="bigButton" to="/category"> <img src={require('../../../assets/img/play.svg')} alt='log2o' className="logo"/>Take your First Test</Link>
                    </div>                   
                </div>  
                <img src={require('../../../assets/img/curve3.png')} alt="banner" className="curve2 desktopOnly"/>             
            </div>
           <Footer/> 
        </>
	);
};

LandingPage.propTypes= {   
    inputChange: PropTypes.func.isRequired,  
}

const mapStateToProps = state => ({ 
    pendingResult: state.auth.pendingResult,
    redirect: state.auth.redirect,
    location: state.auth.location, 
    showResult: state.auth.showResult
})
export default connect(mapStateToProps, {inputChange})(LandingPage);