import React from "react";
import { connect } from 'react-redux';
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { loadSubjects, inputChange } from './../../redux/actions/authActions';

const CategoryBox = props => {
    const handleGotoSubjects = (e) =>{ 
        e.preventDefault()
        props.inputChange('selectedCategoryImg', props.image);
        props.inputChange('selectedCategory', props.title);
        props.loadSubjects(props.categoryId, props.examId);
    }    
	return (        
        <div className="col-md-12">
            <Link to='/category' onClick={ handleGotoSubjects }> 
            <div className="row">
                <div className="col-md-12">
                    <img src={props.imageUrl} alt='category' className="category"/>
                </div>
                <div className="col-md-12">
                    <h5>{props.title}</h5>
                    <p>{props.description}</p>
                </div>               
            </div>
            </Link> 
        </div>        
	);
};

CategoryBox.propTypes= {   
    loadSubjects: PropTypes.func.isRequired,
    inputChange: PropTypes.func.isRequired        
}

export default connect(null, {loadSubjects, inputChange})(CategoryBox);